import './OrderView.css';
import { useEffect, useRef, useState } from 'react';
import orderPage21 from '../../assets/orderPage21.jpg';
import orderPage22 from '../../assets/orderPage22.jpg';
import deleteImg2 from '../../assets/deleteImg2.svg';
import order4 from '../../assets/order4.svg';
import order5 from '../../assets/order5.svg';
import users from '../../assets/users.svg';
import order from '../../assets/order.png';
import order21 from '../../assets/order21.png';
import order31 from '../../assets/order31.png';
import CategoryCards from '../../components/CategoryCards/CategoryCards';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import InputPhone from '../../components/InputPhone/InputPhone';
import { validEmail } from '../../helpers/Utils';
import {
	setIsSuccessFormOrderView,
	setIsViewPreloader,
} from '../../store/homeSlice';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function OrderView() {
	const seo = useSelector((state) => state.homeSlice.seo);
	const utm_source = useSelector((state) => state.homeSlice.utm_source);
	const utm_medium = useSelector((state) => state.homeSlice.utm_medium);
	const utm_campaign = useSelector((state) => state.homeSlice.utm_campaign);
	const scrollPositionBeforeView = useSelector(
		(state) => state.homeSlice.scrollPositionBeforeView
	);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [materials, setMaterials] = useState('');
	const [address, setAddress] = useState('');
	const [comment, setComment] = useState('');
	const [isErrorName, setIsErrorName] = useState(false);
	const [isErrorPhone, setIsErrorPhone] = useState(false);
	const [isErrorEmail, setIsErrorEmail] = useState(false);
	const [isErrorMaterials, setIsErrorMaterials] = useState(false);
	const [isErrorAddress, setIsErrorAddress] = useState(false);
	const [imgArr, setImgArr] = useState([]);
	const [maxImgSize, setMaxImgSize] = useState(12000000);
	const orderFormRef = useRef(null);

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			dispatch(setIsViewPreloader(false));
		}, 200);

		setTimeout(() => {
			window.scroll({
				top: scrollPositionBeforeView[location.pathname]
					? scrollPositionBeforeView[location.pathname]
					: 0,
			});
		}, 10);
	}, []);

	useEffect(() => {
		if (name?.length) {
			setIsErrorName(false);
		}
	}, [name]);

	useEffect(() => {
		if (phone?.length && phone.length >= 12) {
			setIsErrorPhone(false);
		}
	}, [phone]);

	useEffect(() => {
		if (email?.length) {
			setIsErrorEmail(false);
		}
	}, [email]);

	useEffect(() => {
		if (materials?.length) {
			setIsErrorMaterials(false);
		}
	}, [materials]);

	useEffect(() => {
		if (address?.length) {
			setIsErrorAddress(false);
		}
	}, [address]);

	const handleDeleteImg = (obj) => {
		setImgArr((value) => {
			const filteredArr = value.filter((el) => el.file.name !== obj.name);
			URL.revokeObjectURL(obj);
			return filteredArr;
		});
	};

	const handleUploudImg = (img = null) => {
		let valuesArray = [];
		for (let key in img) {
			if (img.hasOwnProperty(key)) {
				valuesArray.push({
					file: img[key],
					src: URL.createObjectURL(img[key]),
				});
			}
		}
		setTimeout(() => {
			setImgArr([...imgArr, ...valuesArray]);
		}, 50);
	};

	const hendleClean = () => {
		setName('');
		setPhone('');
		setEmail('');
		setMaterials('');
		setAddress('');
		setComment('');
		setImgArr([]);
	};

	const hendleTestNewImg = (obj) => {
		return obj.size && obj.size >= maxImgSize ? true : false;
	};

	const hendleTestImg = () => {
		return !imgArr?.length ||
			(imgArr?.length && !imgArr.filter((el) => el.size >= maxImgSize)?.length)
			? true
			: false;
	};

	const hendleSubmitFormGtm = () => {
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({ event: 'submit_form367043475' });
	};

	const hendleSend = (event) => {
		event.preventDefault();
		let sendBtn = document.getElementById('orderViewSendBtnId');
		let btnSpan = document.getElementById('orderViewSendBtnSpanId');
		let loaderClass = 'order-view__loader-btn-send';
		btnSpan.classList.add(loaderClass);
		sendBtn.style.cssText = `
            pointer-events: none;
        `;

		if (
			name?.length &&
			hendleTestImg() &&
			email?.length &&
			phone?.length &&
			phone.length >= 12 &&
			materials?.length &&
			address?.length &&
			validEmail(email) &&
			imgArr.length <= 5
		) {
			let formData = new FormData();
			formData.append('contact_name', name);
			formData.append('contact_phone', phone);
			formData.append('email', email);
			formData.append('address', address);
			formData.append('materials', materials);
			formData.append('description', comment);
			if (imgArr.length) {
				imgArr.map((img) => {
					formData.append('files', img.file);
				});
			} else {
				formData.append('files', []);
			}
			formData.append('nameofpage', location.pathname);
			formData.append('utm_source', utm_source?.length ? utm_source : null);
			formData.append('utm_medium', utm_medium?.length ? utm_medium : null);
			formData.append(
				'utm_campaign',
				utm_campaign?.length ? utm_campaign : null
			);

			fetch(`${process.env.REACT_APP_BASE_URL}/forms/individual_order`, {
				method: 'POST',
				body: formData,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.success && res.data) {
						hendleSubmitFormGtm();
						hendleClean();
						dispatch(setIsSuccessFormOrderView(true));
						navigate('/success');
					} else {
						console.log('POST OrderView:', res);
					}
				});
		} else {
			sendBtn.style.cssText = ``;
			btnSpan.classList.remove(loaderClass);
			setIsErrorName(!name?.length ? true : false);
			setIsErrorPhone(!phone?.length || phone.length < 12 ? true : false);
			setIsErrorEmail(!email.length || !validEmail(email) ? true : false);
			setIsErrorMaterials(!materials?.length ? true : false);
			setIsErrorAddress(!address?.length ? true : false);
		}
	};

	return (
		<div className='order-view'>
			<SeoBlock
				title={seo.title_indivIdual_order}
				description={seo.description_indivIdual_order}
				keywords={seo.keywords_indivIdual_order}
			/>
			<div className='order-view--wrap'>
				<div className='order-view__title-wrap'>
					<img
						className='order-view__title-img order-view__title-img--1'
						src={orderPage22}
						alt='img'
					/>
					<img
						className='order-view__title-img order-view__title-img--2'
						src={orderPage21}
						alt='img'
					/>
					<div className='order-view__title-text-wrap'>
						<h1 className='order-view__title-text'>
							Потрібні меблі на замовлення під проєкт чи ескіз? Сервіс
							індивідуального виготовлення меблів!
						</h1>
						<button
							onClick={() =>
								orderFormRef.current.scrollIntoView({ behavior: 'smooth' })
							}
							className='main-btn-1'
						>
							Подати заявку
						</button>
					</div>
				</div>

				<div className='order-view__who-wrap'>
					<div className='order-view__who container'>
						<div className='order-view__who-title'>
							Ми Вам можемо допомогти виготовити
						</div>
						<div className='order-view__who-items'>
							<div className='order-view__who-item'>
								<img
									className='order-view__who-item-img'
									src={order}
									alt='img'
								/>
								<div className='order-view__who-item-text'>
									Корпусні меблі - кухні, шафи, стелажі, тумби
								</div>
							</div>
							<div className='order-view__who-item'>
								<img
									className='order-view__who-item-img'
									src={order21}
									alt='img'
								/>
								<div className='order-view__who-item-text'>
									Мʼякі меблі - дивани, ліжка, крісла, пуфи
								</div>
							</div>
							<div className='order-view__who-item'>
								<img
									className='order-view__who-item-img'
									src={order31}
									alt='img'
								/>
								<div className='order-view__who-item-text'>
									Меблі з дерева - столи, ліжка, комоди, тумби
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='order-view__how-wrap'>
					<div className='order-view__how container'>
						<div className='order-view__how-title'>Як це працює</div>
						<h2 className='order-view__how-text'>
							Замовити меблі індивідуального виробництва на платформі VYROBY
						</h2>
						<div className='order-view__how-items'>
							<div className='order-view__how-item'>
								<img
									className='order-view__how-item-img'
									src={order4}
									alt='img'
								/>
								<div className='order-view__how-item-title'>
									Заповніть анкету
								</div>
								<div className='order-view__how-item-text'>
									Вкажіть дані в анкету необхідних вам меблів - бажані розміри,
									колір і матеріал. Прикріпіть фото або ескіз потрібного виробу.
									Якщо Ви не знаєте бажаних характеристик, напишіть в коментарях
									хочу консультацію.
								</div>
							</div>
							<div className='order-view__how-item'>
								<img
									className='order-view__how-item-img'
									src={order5}
									alt='img'
								/>
								<div className='order-view__how-item-title'>
									Отримайте прорахунок
								</div>
								<div className='order-view__how-item-text'>
									Ваш запит автоматично отримають виробники, які спеціалізуються
									на меблях для Вашого проєкту, прорахують вартість, терміни
									виконання та відправлять Вам пропозицію, проконсультують.
								</div>
							</div>
							<div className='order-view__how-item'>
								<img
									className='order-view__how-item-img'
									src={users}
									alt='img'
								/>
								<div className='order-view__how-item-title'>
									Виготовлення меблів
								</div>
								<div className='order-view__how-item-text'>
									Якщо пропозиція виробника Вас влаштує, ви зможете замовити
									меблі згідно вашого проєкту безпосередньо у виробника та в
									результаті отримати Ваші індивідуальні меблі.
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='order-view__form-wrap' ref={orderFormRef}>
					<div className='order-view__form container'>
						<div className='order-view__form--wrap'>
							<div className='order-view__form-info'>
								<div className='order-view__form-info-title'>
									Заповніть коротку анкету
								</div>
							</div>
							<div className='order-view__form-input-wrap'>
								<div className='order-view__form-error-wrap'>
									<label
										className='order-view__form-input-label'
										htmlFor='orderViewName'
									>
										Ваше ім`я
									</label>
									<input
										className={`order-view__form-input ${
											isErrorName ? 'order-view__form-error' : ''
										}`}
										onChange={(e) => setName(e.target.value)}
										value={name}
										autoComplete='name'
										id='orderViewName'
										name='name'
										type='text'
										placeholder='Вкажіть ваше ім`я'
									/>
									{isErrorName && (
										<div className='order-view__form-error-text'>
											Обов'язкове поле
										</div>
									)}
								</div>

								<div className='order-view__form-error-wrap'>
									<label
										className='order-view__form-input-label'
										htmlFor='orderViewEmail'
									>
										Ваш email
									</label>
									<input
										className={`order-view__form-input ${
											isErrorEmail ? 'order-view__form-error' : ''
										}`}
										onChange={(e) => setEmail(e.target.value)}
										value={email}
										autoComplete='email'
										id='orderViewEmail'
										name='email'
										type='text'
										placeholder='mail@example.com'
									/>
									{isErrorEmail && (
										<div className='order-view__form-error-text'>
											{!email.length
												? "Обов'язкове поле"
												: 'Вкажіть, будь ласка, коректний email'}
										</div>
									)}
								</div>

								<div
									className={`order-view__form-error-wrap ${
										isErrorPhone ? 'order-view__form-error--phone' : ''
									}`}
								>
									<label
										className='order-view__form-input-label order-view__form-input-label--phone'
										htmlFor='orderViewPhone'
									>
										Ваш телефон
									</label>
									<InputPhone
										phone={phone}
										setPhone={setPhone}
										isOrderVeiw={true}
										isErrorPhone={isErrorPhone}
									/>
									{isErrorPhone && (
										<div className='order-view__form-error-text--phone'>
											Обов'язкове поле
										</div>
									)}
								</div>

								<div className='order-view__form-error-wrap'>
									<label
										className='order-view__form-input-label'
										htmlFor='orderViewMaterials'
									>
										Вкажіть характеристики виробу
									</label>
									<div className='order-view__form-input-sub-label'>
										Розміри, побажання по матеріалу, колір
									</div>
									<input
										className={`order-view__form-input ${
											isErrorMaterials ? 'order-view__form-error' : ''
										}`}
										onChange={(e) => setMaterials(e.target.value)}
										value={materials}
										autoComplete='materials'
										id='orderViewMaterials'
										name='materials'
										type='text'
										placeholder=''
									/>
									{isErrorMaterials && (
										<div className='order-view__form-error-text'>
											Обов'язкове поле
										</div>
									)}
								</div>

								<div className='order-view__form-error-wrap'>
									<label
										className='order-view__form-input-label'
										htmlFor='orderViewAddress'
									>
										Вкажіть адресу доставки
									</label>
									<div className='order-view__form-input-sub-label'>
										Вкажіть місто, вулицю, будинок — це потрібно для прорахунку
										замовлення
									</div>
									<input
										className={`order-view__form-input ${
											isErrorAddress ? 'order-view__form-error' : ''
										}`}
										onChange={(e) => setAddress(e.target.value)}
										value={address}
										autoComplete='address'
										id='orderViewAddress'
										name='address'
										type='text'
										placeholder=''
									/>
									{isErrorAddress && (
										<div className='order-view__form-error-text'>
											Обов'язкове поле
										</div>
									)}
								</div>

								<label
									className='order-view__form-input-label'
									htmlFor='orderViewComment'
								>
									Ваш коментар
								</label>
								<textarea
									className='order-view__form-textarea'
									onChange={(e) => setComment(e.target.value)}
									value={comment}
									autoComplete='comment'
									id='orderViewComment'
									name='comment'
									type='text'
									placeholder=''
								/>

								<label className='order-view__form-input-upload-wrap'>
									<p className='order-view__form-input-upload-lable'>
										Додайте фото
									</p>
									<div className='main-btn-1 order-view__form-input-upload-btn'>
										Завантажити файли
									</div>
									<input
										className='order-view__form-input-upload'
										onChange={(e) => {
											handleUploudImg(e.target.files);
											e.target.value = null;
										}}
										multiple
										type='file'
										accept='image/*'
										id='orderViewImg'
									/>
								</label>
								<div className='order-view__upload-result--wrap'>
									{!!imgArr?.length && (
										<div
											className={`order-view__upload-result-title ${
												imgArr?.length > 5
													? 'order-view__upload-result-title--error'
													: ''
											}`}
										>
											Кількість фотографій обмежена до 5
										</div>
									)}
									{!!imgArr?.length && (
										<div className='order-view__upload-result-img--wrap'>
											{imgArr.map((el, i) => (
												<div className='order-view__upload-result-wrap' key={i}>
													<button
														className='order-view__upload-result-btn order-view__btn-del'
														onClick={() => handleDeleteImg(el.file)}
													>
														<img
															className='order-view__upload-result-del-btn'
															src={deleteImg2}
															alt='img'
														/>
													</button>
													<img
														className='order-view__upload-result-img'
														src={el.src}
														alt='img'
													/>
													{hendleTestNewImg(el.file) && (
														<div className='order-view__upload-result-img-error order-view__btn-del'>
															Розмір картинки перевищує 12Mb
														</div>
													)}
												</div>
											))}
										</div>
									)}
								</div>

								{(isErrorName ||
									isErrorMaterials ||
									isErrorAddress ||
									isErrorEmail ||
									isErrorPhone) && (
									<div className='contacts-view__form-error-banner'>
										{(isErrorName ||
											isErrorMaterials ||
											isErrorAddress ||
											isErrorPhone) && (
											<div className='contacts-view__form-error-banner-text'>
												Будь ласка, заповніть всі обов'язкові поля
											</div>
										)}
										{isErrorEmail &&
											((!isErrorName &&
												!isErrorMaterials &&
												!isErrorAddress &&
												!isErrorPhone) ||
												!!email.length) && (
												<div className='contacts-view__form-error-banner-text'>
													{!email.length
														? "Будь ласка, заповніть всі обов'язкові поля"
														: 'Вкажіть, будь ласка, коректний email'}
												</div>
											)}
									</div>
								)}
								<br />
								<button
									id='orderViewSendBtnId'
									className='main-btn-1 order-view__main-text-btn--1'
									onClick={hendleSend}
								>
									<span
										id='orderViewSendBtnSpanId'
										className='order-view__loader-btn'
									></span>
									Надіслати запит
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className='order-view__category-wrap container'>
					<div className='order-view__category-title'>
						Також у нас є готові рішення
					</div>
					<div className='order-view__category-text'>
						Переходьте в каталог виробів і обирайте меблі від українських
						виробників
					</div>
				</div>
				<div className='order-view__category-cards-wrap'>
					<CategoryCards />
				</div>
			</div>
		</div>
	);
}

export default OrderView;
