import './ProductView.css';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	fetchGetData,
	fetchRequest,
	handleCategoriesMap,
	handlePriceReplace,
	handleCheckShortId,
	enabledScroll,
	disabledScroll,
	validEmail,
	handleOpenNewCategories,
	handleReturnProductCategoryAll,
	handleReturnItemVariantGtm,
} from '../../helpers/Utils';
import noPhotos from '../../assets/noPhotos.svg';
import closeImg from '../../assets/closeImg.svg';
import closeImgBlack from '../../assets/closeImgBlack.svg';
import likeEllipseHover from '../../assets/likeEllipseHover.svg';
import likeEllipse from '../../assets/likeEllipse.svg';
import textImg from '../../assets/textImg.svg';
import brush from '../../assets/brush.svg';
import playCircle from '../../assets/playCircle.svg';
import arrow2Green from '../../assets/arrow2Green.svg';
import textImgGreen from '../../assets/textImgGreen.svg';
import brushGreen from '../../assets/brushGreen.svg';
import arrow2 from '../../assets/arrow2.svg';
import arrow5 from '../../assets/arrow5.svg';
import square from '../../assets/square.svg';
import { Drawer, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../../components/ProductCard/ProductCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Navigation, Zoom } from 'swiper/modules';
import {
	setLikeProducts,
	setShoppingProducts,
	setIsScrollSlider,
	setOldModalScrollPosition,
	setIsSuccessFormOrderView,
	setIsOpenShoppingCart,
	setModalShoppingScrollPosition,
} from '../../store/homeSlice';
import ColorResult from '../../components/ColorResult/ColorResult';
import MaterialResult from '../../components/MaterialResult/MaterialResult';
import ReactPlayer from 'react-player';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { setShowMessageObj } from '../../store/userSlice';
import InputPhone from '../../components/InputPhone/InputPhone';
import { standardColors } from '../../helpers/Config';
import PaginationSwiper from '../../components/PaginationSwiper/PaginationSwiper';
import SeoBlock from '../../components/SeoBlock/SeoBlock';
import VariationsCard from './VariationsCard/VariationsCard';
import { useScroll } from '../../hooks/useScroll';
import Preloader from '../../components/common/Preloader/Preloader';

function ProductView() {
	const categoriesShop = useSelector((state) => state.homeSlice.categoriesShop);
	const shoppingProducts = useSelector(
		(state) => state.homeSlice.shoppingProducts
	);
	const likeProducts = useSelector((state) => state.homeSlice.likeProducts);
	const utm_source = useSelector((state) => state.homeSlice.utm_source);
	const utm_medium = useSelector((state) => state.homeSlice.utm_medium);
	const utm_campaign = useSelector((state) => state.homeSlice.utm_campaign);

	const [product, setProduct] = useState({});
	const [vendor, setVendor] = useState({});
	const [productCount, setProductCount] = useState(1);
	const [productCountForm, setProductCountForm] = useState(1);
	const [page, setPage] = useState(0);
	const [productsCount, setProductsCount] = useState(0);
	const [tab, setTab] = useState(1);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [openModalForm, setOpenModalForm] = useState(false);
	const [openVideoModalForm, setOpenVideoModalForm] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [address, setAddress] = useState('');
	const [description, setDescription] = useState('');
	const [isLiked, setIsLiked] = useState(false);
	const [isOpenMenuVariations, setIsOpenMenuVariations] = useState(false);
	const [videoPlay, setVideoPlay] = useState('');
	const [categoryProducts, setCategoryProducts] = useState([]);
	const [openModalZoom, setOpenModalZoom] = useState(false);
	const [isModaAdaptlForm, setIsModaAdaptlForm] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [isOpenProductInfo, setIsOpenProductInfo] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [openProductInfo, setOpenProductInfo] = useState(null);
	const [swiperActiveIndex, setSwiperActiveIndex] = useState(0);
	const [swiper, setSwiper] = useState(null);
	const [modalScrollPosition, setModalScrollPosition] = useState(0);
	const [isErrorName, setIsErrorName] = useState(false);
	const [isErrorPhone, setIsErrorPhone] = useState(false);
	const [isErrorEmail, setIsErrorEmail] = useState(false);
	const [isErrorDescription, setIsErrorDescription] = useState(false);
	const [isErrorAddress, setIsErrorAddress] = useState(false);
	const [isBeginCheckout, setIsBeginCheckout] = useState(false);
	const [isPreloader, setIsPreloader] = useState(false);
	const [zoomImg, setZoomImg] = useState('');
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const isXiaomiRedmi = /xiaomi/i.test(navigator.userAgent);
	let swiperRef = useRef(null);
	const swiperWrapRef = useRef(null);
	const swiper__1Ref = useRef(null);
	const formWrap = useRef(null);
	const swiperModalZoomRef = useRef(null);
	const productViewRef = useRef(null);
	const openModalFormRef = useRef(null);
	const zoomWrapRef = useRef(null);
	let { productId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const { savedScrollY } = useScroll();

	const savedScrollYfromLs = localStorage.getItem('scrollY');
	const savedScrollYRecords =
		savedScrollYfromLs !== null ? JSON.parse(savedScrollYfromLs) : {};

	const handleRefreshSavedScroll = (path) => {
		if (savedScrollYRecords[path]) {
			delete savedScrollYRecords[path];

			localStorage.setItem('scrollY', JSON.stringify(savedScrollYRecords));
		}
	};

	const slideTo = (index) => {
		if (swiper) swiper.slideTo(index);
	};

	useEffect(() => {
		setIsMobile(window.innerWidth <= 600);
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			window.visualViewport.removeEventListener('resize', handleZoomChange);
			swiperRef.current = null;

			dispatch(setIsScrollSlider(false));
			dispatch(setOldModalScrollPosition(0));
			enabledScroll(modalScrollPosition);
		};
	}, []);

	useEffect(() => {
		if (swiperRef && swiperRef?.current) {
			window.visualViewport.addEventListener('resize', handleZoomChange);
		}
	}, [swiperRef.current]);

	useEffect(() => {
		if (product._id?.length && categoriesShop?.length) {
			handleViewItemGtm();
		}
	}, [product, categoriesShop]);

	useEffect(() => {
		setVideoPlay('');
		if (product._id?.length) {
			let res = shoppingProducts.find((el) => el._id === product._id);
			if (res) {
				setProductCount(res.count);
			}
		}

		if (product.category_id?.length) {
			handleGetProducts();
		}

		if (product.vendor_id?.length && product.vendor_id !== vendor._id) {
			fetchGetData(
				`${process.env.REACT_APP_BASE_URL}/vendors/${product.vendor_id}`
			).then((res) => {
				if (res.success && res.data) {
					setVendor(res.data);
				} else {
					console.log('GET ProductView', res);
				}
			});
		}

		setIsLiked(!!likeProducts.find((el) => el._id === product._id));
	}, [product]);

	useEffect(() => {
		if (product._id && categoryProducts.length) {
			setTimeout(() => {
				window.scroll({
					top: savedScrollY,
				});
			}, 0);
			setTimeout(() => {
				setIsPreloader(false);
			}, 150);
		}
	}, [product._id, categoryProducts]);

	useEffect(() => {
		if (page !== 0) {
			handleGetProducts(true);
		}
	}, [page]);

	useEffect(() => {
		setCategoryProducts([]);
		setPage(0);
		slideTo(0);
		if (productId?.length && productId.split('-')[0] !== product.short_id) {
			handleGetProduct();
			setModalScrollPosition(0);
		}
	}, [productId]);

	useLayoutEffect(() => {
		setIsPreloader(true);
	}, [productId]);

	useEffect(() => {
		if (handleIsShoppingProducts) {
			dispatch(
				setShoppingProducts([
					...shoppingProducts.map((el) => {
						if (el._id === product._id) {
							el = { ...product, count: productCount };
							return el;
						}
						return el;
					}),
				])
			);
		}
	}, [productCount]);

	useEffect(() => {
		if (name?.length) {
			setIsErrorName(false);
			handleaBeginCheckoutGtm([product]);
			setIsBeginCheckout(true);
		}
	}, [name]);

	useEffect(() => {
		if (phone?.length && phone.length >= 12) {
			setIsErrorPhone(false);
			handleaBeginCheckoutGtm([product]);
			setIsBeginCheckout(true);
		}
	}, [phone]);

	useEffect(() => {
		if (email?.length) {
			setIsErrorEmail(false);
			handleaBeginCheckoutGtm([product]);
			setIsBeginCheckout(true);
		}
	}, [email]);

	useEffect(() => {
		if (description?.length) {
			setIsErrorDescription(false);
			handleaBeginCheckoutGtm([product]);
			setIsBeginCheckout(true);
		}
	}, [description]);

	useEffect(() => {
		if (address?.length) {
			setIsErrorAddress(false);
			handleaBeginCheckoutGtm([product]);
			setIsBeginCheckout(true);
		}
	}, [address]);

	const handleViewItemGtm = () => {
		window.dataLayer.push({ ecommerce: null });
		let res = handleReturnProductCategoryAll(product, categoriesShop);
		window.dataLayer.push({
			event: 'view_item',
			ecommerce: {
				value: product.price.toFixed(2),
				currency: 'UAH',
				items: [
					{
						item_name: product.title,
						item_id: product._id,
						id: product._id,
						price: product.price.toFixed(2),
						item_brand: !!product.vendor_name?.length
							? product.vendor_name
							: !!vendor.company_name?.length
							? vendor.company_name
							: '',
						item_category: res?.cat?.name?.length ? res?.cat?.name : '',
						item_category2: res?.subCat?.name ? res?.subCat?.name : '',
						item_variant: handleReturnItemVariantGtm(product),
						google_business_vertical: 'retail',
					},
				],
			},
		});
	};

	const handleaAdToCartGtm = () => {
		let res = handleReturnProductCategoryAll(product, categoriesShop);
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'add_to_cart',
			ecommerce: {
				currency: 'UAH',
				value: (product.price * Number(productCount)).toFixed(2),
				items: [
					{
						google_business_vertical: 'retail',
						item_name: product.title,
						id: product._id,
						item_id: product._id,
						price: product.price.toFixed(2),
						item_brand: !!product.vendor_name?.length
							? product.vendor_name
							: !!vendor.company_name?.length
							? vendor.company_name
							: '',
						item_category: res?.cat?.name?.length ? res?.cat?.name : '',
						item_category2: res?.subCat?.name ? res?.subCat?.name : '',
						item_variant: handleReturnItemVariantGtm(product),
						quantity: Number(productCount),
					},
				],
			},
		});
	};

	const handleaBeginCheckoutGtm = (products) => {
		if (!isBeginCheckout) {
			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push({
				event: 'begin_checkout',
				ecommerce: {
					items: [
						...products.map((el) => {
							return {
								item_name: el.title,
								item_id: el._id,
								price: el.price.toFixed(2),
								item_brand: el.vendor_name,
								item_category: handleReturnProductCategoryAll(
									el,
									categoriesShop
								)?.cat?.name?.length
									? handleReturnProductCategoryAll(el, categoriesShop)?.cat
											?.name
									: '',
								item_category2: handleReturnProductCategoryAll(
									el,
									categoriesShop
								)?.subCat?.name
									? handleReturnProductCategoryAll(el, categoriesShop)?.subCat
											?.name
									: '',
								item_variant: handleReturnItemVariantGtm(el),
								quantity: 1,
							};
						}),
					],
				},
			});
		}
	};

	const handleaPurchaseGtm = (arr) => {
		if (arr?.length) {
			arr.map((item) => {
				window.dataLayer.push({ ecommerce: null });
				window.dataLayer.push({
					event: 'form_request',
					ecommerce: {
						transaction_id: item.id,
						affiliation: 'fast order',
						value: product.price * productCountForm,
						tax: '0',
						shipping: '0',
						currency: 'UAH',
						items: [
							{
								item_name: product.title,
								item_id: product._id,
								price: product.price.toFixed(2),
								item_brand: product.vendor_name,
								item_category: handleReturnProductCategoryAll(
									product,
									categoriesShop
								)?.cat?.name?.length
									? handleReturnProductCategoryAll(product, categoriesShop)?.cat
											?.name
									: '',
								item_category2: handleReturnProductCategoryAll(
									product,
									categoriesShop
								)?.subCat?.name
									? handleReturnProductCategoryAll(product, categoriesShop)
											?.subCat?.name
									: '',
								item_variant: handleReturnItemVariantGtm(product),
								quantity: productCountForm,
								id: product._id,
								google_business_vertical: 'retail',
							},
						],
					},
				});
			});
		}
	};

	const handleZoomChange = (event) => {
		const newZoomLevel = event.target.scale;
		if (newZoomLevel > 1) {
			if (swiperRef && swiperRef?.current) {
				swiperRef.current.swiper.allowTouchMove = false;
			}
		} else {
			if (swiperRef && swiperRef?.current) {
				swiperRef.current.swiper.allowTouchMove = true;
			}
		}
	};

	const handlePageCount = useMemo(() => {
		return (num) => {
			if (num !== 0) {
				return num % 4 === 0 ? num / 4 : Math.ceil(num / 4);
			}
		};
	}, [productsCount]);

	const handleGoCatalog = () => {
		let res = `/catalog`;
		let res2 = handleCategoriesMap(categoriesShop).find(
			(el) => el._id === product.category_id
		);
		if (res2?._id?.length) {
			res = handleOpenNewCategories(res2, categoriesShop);
		}
		return res;
	};

	const handleIsShoppingProducts = () => {
		return shoppingProducts.find((el) => el._id === product._id) ? true : false;
	};

	const handleAddShoppingProducts = () => {
		if (!handleIsShoppingProducts()) {
			dispatch(
				setShoppingProducts([
					...shoppingProducts,
					{ ...product, count: productCount },
				])
			);
			handleaAdToCartGtm();
		}
		dispatch(setIsOpenShoppingCart({ isOpen: true, isOpenByButton: false }));
		dispatch(setModalShoppingScrollPosition(window.scrollY));
		document.body.style.cssText = `
            overflow: hidden;
            position: fixed;
            top: -${window.scrollY}px;
            left: 0;
            width: 100vw;
            padding-right: ${window.innerWidth - document.body.offsetWidth}px
            `;
		document.documentElement.style.scrollBehavior = 'unset';
	};

	const handleGetProduct = () => {
		setIsPreloader(true);
		fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/products/${productId.split('-')[0]}`
		).then((res) => {
			if (res?.success && res?.data) {
				setProduct(res.data);
				setThumbsSwiper(null);
				let historyProducts = localStorage.getItem('historyProducts');
				if (historyProducts?.length) {
					let arr = JSON.parse(historyProducts);
					if (
						!arr.some((el) => el.variation_code === res.data.variation_code)
					) {
						localStorage.setItem(
							'historyProducts',
							JSON.stringify([res.data, ...arr].slice(0, 5))
						);
					}
				} else {
					localStorage.setItem('historyProducts', JSON.stringify([res.data]));
				}
			} else {
				navigate('/not-found');
				console.log('GET ProductView', res);
			}
		});
	};

	const handleGetProducts = (isNextPage = false) => {
		let data = {
			page: page,
			category_id: product?.category_id?.length ? [product.category_id] : [],
			type: [],
			perPages: 12,
			catalog: true,
			count_product_variations: false,
			exclude: product?.variation_code,
		};

		fetchRequest(
			'POST',
			`${process.env.REACT_APP_BASE_URL}/products/admin/all`,
			data
		).then((res) => {
			if (res.success && res.data) {
				if (isNextPage) {
					setCategoryProducts([...categoryProducts, ...res.data]);
				} else {
					setCategoryProducts(res.data);
				}
				setProductsCount(res.count);
			} else {
				console.log('POST ProductView:', res);
			}
		});
	};

	const handleCountItems = () => {
		let sum = 0;
		if (!!product.video_link_1?.length) {
			sum = sum + 1;
		}
		if (!!product.video_link_2?.length) {
			sum = sum + 1;
		}
		if (!!product.model_3d?.length) {
			sum = sum + 1;
		}
		return sum;
	};

	const handleWidthWrap = () => {
		if (swiperWrapRef.current) {
			let sum = handleCountItems();
			if (sum === 1) {
				return swiperWrapRef.current.clientWidth - 175;
			}
			if (sum === 2) {
				return swiperWrapRef.current.clientWidth - 270;
			}
			if (sum === 3) {
				return swiperWrapRef.current.clientWidth - 365;
			}
			return swiperWrapRef.current.clientWidth - 80;
		}
	};

	const handleHeightWrap = () => {
		if (swiperRef.current) {
			let sum = handleCountItems();
			if (sum === 1) {
				return swiperRef.current.clientHeight - 199 < 170
					? 170
					: swiperRef.current.clientHeight - 199;
			}
			if (sum === 2) {
				return swiperRef.current.clientHeight - 310 < 170
					? 170
					: swiperRef.current.clientHeight - 310;
			}
			if (sum === 3) {
				return swiperRef.current.clientHeight - 397 < 170
					? 170
					: swiperRef.current.clientHeight - 397;
			}
			return swiperRef.current.clientHeight - 80;
		}
	};

	const handleIsDisplayBtn = () => {
		let res;
		if (product.images?.length) {
			if (windowWidth > 960) {
				res = handleHeightWrap() > product.images?.length * 95 ? true : false;
			} else {
				res = handleWidthWrap() > product.images?.length * 95 ? true : false;
			}
		}
		return res;
	};

	const handleClose = () => {
		setOpenModalZoom(false);
		setInitialSlideIndex(0);
		enabledScroll(modalScrollPosition);
		setZoomImg('');
	};

	const handleOpenModalZoom = (index) => {
		if (windowWidth > 960) {
			setOpenModalZoom(true);
			setInitialSlideIndex(index);
			disabledScroll(setModalScrollPosition);
		}
	};

	const handleAdaptForm = () => {
		setOpenModalForm(true);
		setIsModaAdaptlForm(true);
		disabledScroll(setModalScrollPosition);
	};

	const handleOpenProductInfo = (num) => {
		setIsOpenProductInfo(true);
		setOpenProductInfo(num);
		disabledScroll(setModalScrollPosition);
	};

	const handleCloseProductInfo = () => {
		setIsOpenProductInfo(false);
		setOpenProductInfo(null);
		enabledScroll(modalScrollPosition);
	};

	const handleIsOpenMenuVariations = (boolean) => {
		setIsOpenMenuVariations(boolean);
		if (boolean) {
			disabledScroll(setModalScrollPosition);
		} else {
			enabledScroll(modalScrollPosition);
		}
	};

	const handleCloseModalForm = () => {
		setName('');
		setPhone('');
		setEmail('');
		setAddress('');
		setDescription('');
		setProductCountForm(1);
		setOpenModalForm(false);
		setIsModaAdaptlForm(false);
		enabledScroll(modalScrollPosition);
		setIsErrorName(false);
		setIsErrorPhone(false);
		setIsErrorEmail(false);
		setIsErrorDescription(false);
		setIsErrorAddress(false);
	};

	const handleVideoClose = () => {
		setOpenVideoModalForm(false);
		enabledScroll(modalScrollPosition);
		setVideoPlay('');
	};

	const handleOpenModalForm = () => {
		setOpenModalForm(true);
		disabledScroll(setModalScrollPosition);
	};

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
		handleIsDisplayBtn();
	};

	const handleSendForm = (event) => {
		event.preventDefault();
		let sendBtn = document.getElementById('productViewSendBtnId');
		sendBtn.style.cssText = `
            pointer-events: none;
        `;
		if (
			name?.length &&
			email?.length &&
			phone?.length &&
			phone.length >= 12 &&
			description?.length &&
			address?.length &&
			validEmail(email)
		) {
			let data = {
				full_name: name,
				phone: phone,
				email: email,
				delivery_address: address,
				count: productCountForm,
				message: description,
				product_id: product._id,
				nameofpage: location,
				request_type: isModaAdaptlForm ? 'adapt' : 'question',
				utm_source: utm_source?.length ? utm_source : null,
				utm_medium: utm_medium?.length ? utm_medium : null,
				utm_campaign: utm_campaign?.length ? utm_campaign : null,
			};

			fetchRequest(
				'POST',
				`${process.env.REACT_APP_BASE_URL}/forms/question_order`,
				data
			).then((res) => {
				if (res.success && res.data) {
					handleaPurchaseGtm(res.data);
					dispatch(setIsSuccessFormOrderView(true));
					navigate('/success');
					handleCloseModalForm();
				} else {
					console.log('POST ProductView:', res);
				}
			});
		} else {
			sendBtn.style.cssText = ``;
			setIsErrorName(!name?.length ? true : false);
			setIsErrorPhone(!phone?.length || phone.length < 12 ? true : false);
			setIsErrorEmail(!email.length || !validEmail(email) ? true : false);
			setIsErrorDescription(!description?.length ? true : false);
			setIsErrorAddress(!address?.length ? true : false);
		}
	};

	const handleClickOpenProductVariations = (obj, isMobile = false) => {
		if (isMobile) {
			if (windowWidth < 960) {
				setIsOpenMenuVariations(false);
				enabledScroll(modalScrollPosition);
			}
		}

		let resArr = [];
		let res = [
			...product.characteristics.filter((el) =>
				product.variations.find((ell) => ell.name === el.name)
			),
		];
		if (res?.length) {
			resArr = [
				...res.map((el) => {
					if (el.name === obj.name) {
						return obj;
					}
					return el;
				}),
			];
		}
		if (obj.name === 'Колір' || obj.name === 'Матеріал') {
			resArr = [...resArr, obj];
		} else {
			if (product.color?.length) {
				resArr.push({
					name: 'Колір',
					value: {
						color: product.color,
						color_name: product.color_name,
						color_filter: product.color_filter,
					},
				});
			}
			if (product.material?.title?.length) {
				resArr.push({ name: 'Матеріал', value: { ...product.material } });
			}
		}

		let data = {
			_id: product._id,
			variations: resArr,
		};
		fetchRequest(
			'POST',
			`${process.env.REACT_APP_BASE_URL}/products_variations/gettest`,
			data,
			() =>
				dispatch(
					setShowMessageObj({
						open: true,
						status: 'error',
						message: 'Сталася помилка',
					})
				)
		).then((res) => {
			if (res?.success && res?.data) {
				const variationPath = handleCheckShortId(res?.data);
				handleRefreshSavedScroll(variationPath);
				if (window.innerWidth > 600) {
					localStorage.setItem(
						'scrollY',
						JSON.stringify({
							...savedScrollYRecords,
							[variationPath]: window.scrollY,
						})
					);
				}
				setProduct(res.data);
				setThumbsSwiper(null);
				let historyProducts = localStorage.getItem('historyProducts');
				if (historyProducts?.length) {
					let arr = JSON.parse(historyProducts);
					if (
						!arr.some((el) => el.variation_code === res.data.variation_code)
					) {
						localStorage.setItem(
							'historyProducts',
							JSON.stringify([res.data, ...arr].slice(0, 5))
						);
					}
				} else {
					localStorage.setItem('historyProducts', JSON.stringify([res.data]));
				}
				navigate(handleCheckShortId(res?.data));
			} else {
				navigate('/');
				console.log('GET ProductView', res);
			}
		});
	};

	const handleLikeGtm = (product) => {
		let res = handleReturnProductCategoryAll(product, categoriesShop);
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'add_to_wishlist',
			ecommerce: {
				value: product.price.toFixed(2),
				currency: 'UAH',
				items: [
					{
						google_business_vertical: 'retail',
						item_name: product.title,
						item_id: product._id,
						id: product._id,
						price: product.price.toFixed(2),
						item_brand: !!product.vendor_name?.length
							? product.vendor_name
							: !!vendor.company_name?.length
							? vendor.company_name
							: '',
						item_category: res?.cat?.name?.length ? res?.cat?.name : '',
						item_category2: res?.subCat?.name ? res?.subCat?.name : '',
						item_variant: handleReturnItemVariantGtm(product),
						quantity: Number(productCount),
					},
				],
			},
		});
	};

	const handleLike = (obj) => {
		if (likeProducts?.length) {
			let res = likeProducts.find((el) => el._id === obj._id);
			if (res) {
				setIsLiked(false);
				let res2 = likeProducts.filter((el) => el._id !== obj._id);
				dispatch(setLikeProducts(res2));
				localStorage.setItem(
					'likeProductsIds',
					JSON.stringify([...res2.map((el) => el._id)])
				);
			} else {
				setIsLiked(true);
				handleLikeGtm(obj);
				dispatch(setLikeProducts([...likeProducts, obj]));
				localStorage.setItem(
					'likeProductsIds',
					JSON.stringify([...likeProducts.map((el) => el._id), obj._id])
				);
			}
		} else {
			setIsLiked(true);
			handleLikeGtm(obj);
			dispatch(setLikeProducts([obj]));
			localStorage.setItem('likeProductsIds', JSON.stringify([obj._id]));
		}
	};

	const handleNextVariation = (obj, oldProductId) => {
		if (categoryProducts?.length) {
			let res = categoryProducts.map((ell) => {
				if (ell._id === oldProductId) {
					ell = obj;
					return ell;
				}
				return ell;
			});

			setCategoryProducts(res);
		}
	};

	const handlePrevSleder = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
			setVideoPlay('');
		}
	};

	const handleNextSleder = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
			setVideoPlay('');
		}
	};

	const handlePrevModalSleder = () => {
		if (swiperModalZoomRef.current && swiperModalZoomRef.current.swiper) {
			swiperModalZoomRef.current.swiper.slideNext();
			setZoomImg('');
		}
	};

	const handleNextModalSleder = () => {
		if (swiperModalZoomRef.current && swiperModalZoomRef.current.swiper) {
			swiperModalZoomRef.current.swiper.slidePrev();
			setZoomImg('');
		}
	};

	const openVideoModal = (url) => {
		if (isMobile) {
			setOpenVideoModalForm(true);
			disabledScroll(setModalScrollPosition);
		}

		setVideoPlay(url);
	};

	const handleCheckCharacteristics = useMemo(() => {
		return (arr) => {
			let res = [];
			let characteristicsArr = handleCategoriesMap(categoriesShop).find(
				(el) => el._id === product.category_id
			)?.characteristics_options;
			if (characteristicsArr?.length) {
				res = arr.filter((el) =>
					characteristicsArr.some(
						(ell) => ell.name === el.name && ell.is_hide !== true
					)
				);
			}
			return res?.length ? [...res] : [];
		};
	}, [product, categoriesShop]);

	const handleSlideChangeTransitionStart2 = (swiper) => {
		setSwiperActiveIndex(swiper.realIndex);
	};

	const handleMouseMove = (e) => {
		const zoomWrap = zoomWrapRef.current;
		if (!zoomWrap) return;
		const deltaY = e.nativeEvent.movementY;
		if (deltaY !== 0) {
			if (isSafari) {
				zoomWrap.scrollTop += deltaY * 2.7;
			} else {
				zoomWrap.scrollTop += deltaY * 2;
			}
		}
	};

	return (
		<div className='product-view' ref={productViewRef}>
			<SeoBlock
				title={product.seo_default_title}
				description={product.seo_default_description}
				keywords={product.seo_default_keywords}
				isCanonical={true}
				image={product.images?.length ? product.images[0] : null}
			/>
			{isPreloader ? <Preloader /> : null}

			{isOpenProductInfo && (
				<div className='vendor-view__modal-wrap'>
					<div>
						<div
							className='vendor-view__modal-form--wrap'
							onClick={handleCloseProductInfo}
						></div>
						<div
							className={`vendor-view__text-modal-tab-wrap ${
								isSafari || isXiaomiRedmi
									? 'product-view__text-modal-tab-is-safari-wrap'
									: ''
							}`}
						>
							<div className='vendor-view__text-modal-tab-title-wrap'>
								{openProductInfo === 1 && <>Характеристики</>}
								{openProductInfo === 2 && <>Доставка та оплата</>}
								{openProductInfo === 3 && <>Опис</>}
								<button
									className='product-view__text-modal-tab-btn-close-wrap'
									onClick={handleCloseProductInfo}
								>
									<svg
										className='product-view__text-modal-tab-btn-close'
										viewBox='0 0 14 14'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M1.27312 13.5269L0.473145 12.7269L6.20007 7L0.473145 1.27307L1.27312 0.473099L7.00004 6.20002L12.727 0.473099L13.5269 1.27307L7.80002 7L13.5269 12.7269L12.727 13.5269L7.00004 7.79997L1.27312 13.5269Z'
											fill='black'
										/>
									</svg>
								</button>
							</div>
							{openProductInfo === 1 && (
								<div className='product-view__text-tab-1 product-view__text-tab-1--mob'>
									<div className='product-view__text-tab-1-title'>
										Про виріб
									</div>
									<div>
										Тип виробництва:{' '}
										<span className='product-view__text-tab-1-value'>
											{product.type === 'serial' ? 'Серійне' : 'Індивідуальне'}
										</span>
									</div>
									<div>
										Бренд:{' '}
										<span className='product-view__text-tab-1-value'>
											{!!product.vendor_name?.length
												? product.vendor_name
												: !!vendor.company_name?.length
												? vendor.company_name
												: ''}
										</span>
									</div>
									<div>
										Категорія:{' '}
										<span className='product-view__text-tab-1-value'>
											{product.category_name}
										</span>
									</div>
									{!!product.material?.title?.length && (
										<div>
											Матеріал:{' '}
											<span className='product-view__text-tab-1-value'>
												{product.material?.title}
											</span>
										</div>
									)}
									{!!standardColors?.length ? (
										<>
											{!!product.color_name?.length && (
												<div>
													Колір:{' '}
													<span className='product-view__text-tab-1-value'>
														{product.color_name}
													</span>
												</div>
											)}
										</>
									) : (
										<>
											{!!product.color_filter?.length && (
												<div>
													Колір:{' '}
													<span className='product-view__text-tab-1-value'>
														{
															standardColors.find(
																(el) => el.value === product.color_filter
															).name
														}
													</span>
												</div>
											)}
										</>
									)}
									{!!product.characteristics?.length &&
										!!handleCheckCharacteristics(product.characteristics)
											?.length &&
										handleCheckCharacteristics(product.characteristics).map(
											(el, i) => (
												<div key={el.name + i}>
													{el.name}:{' '}
													<span className='product-view__text-tab-1-value'>
														{el.value}
													</span>
												</div>
											)
										)}
								</div>
							)}
							{openProductInfo === 3 && (
								<div
									className={`product-view__text-tab-3 product-view__text-tab-3--mob ${
										isSafari && windowWidth < 640
											? 'product-view__form-input-is-safari-wrap'
											: ''
									}`}
								>
									<pre className='product-view__product-text-description'>
										{product.description}
									</pre>
								</div>
							)}
							{openProductInfo === 2 && (
								<div className='product-view__text-tab-2 product-view__text-tab-2--mob'>
									<div>
										<div className='product-view__text-tab-2-title'>
											Варіанти доставки:
										</div>
										{!!vendor.conditions_delivery?.length && (
											<div className='product-view__text-tab-1-conditions_delivery'>
												{vendor.conditions_delivery}
											</div>
										)}
										<ul className='product-view__text-tab-2-ul'>
											{!!vendor.delivery_options?.length &&
												vendor.delivery_options.map((el, i) => (
													<li
														className='product-view__text-tab-2-li'
														key={el + i}
													>
														{el}
													</li>
												))}
										</ul>
									</div>
									<div>
										<div className='product-view__text-tab-2-title'>
											Варіанти оплати:
										</div>
										{!!vendor.conditions_payment?.length && (
											<div className='product-view__text-tab-1-conditions_delivery'>
												{vendor.conditions_payment}
											</div>
										)}
										<ul className='product-view__text-tab-2-ul'>
											{!!vendor.payment_options?.length &&
												vendor.payment_options.map((el, i) => (
													<li
														className='product-view__text-tab-2-li'
														key={el + i}
													>
														{el}
													</li>
												))}
										</ul>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			)}

			<Modal open={openModalZoom} onClose={handleClose}>
				<div className={`product-view__modal-zoom-wrap`}>
					<button
						className='product-view__modal-zoom-btn-1--wrap'
						onClick={handleNextModalSleder}
					>
						<div className='product-view__modal-zoom-btn-1-wrap'>
							<img
								className='product-view__modal-zoom-btn-1'
								src={arrow5}
								alt='img'
							/>
						</div>
					</button>
					{!!zoomImg?.length && (
						<div className='product-view__modal-zoom-img-wrap'>
							<div
								id='zoomWrapId'
								ref={zoomWrapRef}
								onMouseMove={handleMouseMove}
								className={`product-view__modal-zoom-img-wrap2 ${
									zoomImg?.length ? 'product-view__modal-zoom-img-is-zoom' : ''
								}`}
							>
								<img
									className={`product-view__modal-zoom-img ${
										zoomImg?.length ? 'product-view__img-is-zoom' : ''
									}`}
									src={zoomImg}
									alt='Зображення'
									onClick={() => setZoomImg('')}
								/>
							</div>
						</div>
					)}
					<Swiper
						ref={swiperModalZoomRef}
						loop={true}
						spaceBetween={10}
						navigation={true}
						modules={[Navigation]}
						initialSlide={initialSlideIndex}
						className={`product-view__modal-zoom-swiper-wrap ${
							zoomImg?.length ? 'product-view__modal-zoom-swiper-is-zoom' : ''
						}`}
					>
						{!!product.images?.length &&
							product.images.map((img, i) => (
								<SwiperSlide key={img + i}>
									<img
										id={`zoomImageWrap-${img}`}
										className={`product-view__modal-zoom-img `}
										src={img}
										alt='Зображення'
										onClick={() => setZoomImg(img)}
									/>
								</SwiperSlide>
							))}
					</Swiper>

					<button
						className={`product-view__modal-zoom-btn-2--wrap`}
						onClick={handlePrevModalSleder}
					>
						<div className='product-view__modal-zoom-btn-2-wrap'>
							<img
								className='product-view__modal-zoom-btn-2'
								src={arrow5}
								alt='img'
							/>
						</div>
					</button>
					<img
						className='product-view__modal-zoom-btn-close-img'
						onClick={handleClose}
						src={closeImgBlack}
						alt='img'
					/>
				</div>
			</Modal>

			<Drawer
				anchor='right'
				open={isOpenMenuVariations}
				onClose={() => handleIsOpenMenuVariations(false)}
				className='product-view__drawer'
			>
				<div className='product-view__drawer-result--wrap'>
					{!!product.variations?.length &&
						product.variations.map((el, i) => {
							if (el.name === 'Колір') {
								return (
									<div key={el.name + i}>
										<div className='product-view__drawer-result-title-wrap'>
											<div className='product-view__drawer-result-title'>
												Доступні варіанти кольору
											</div>
											<img
												className='product-view__drawer-result-title-btn'
												onClick={() => handleIsOpenMenuVariations(false)}
												src={closeImgBlack}
												alt='img'
											/>
										</div>
										<div className='product-view__drawer-result-wrap'>
											{el.value.map((variation, index) => (
												<div
													onClick={() =>
														handleClickOpenProductVariations(
															{ name: el.name, value: variation },
															true
														)
													}
													key={variation.color + index}
												>
													<ColorResult
														productColor={product.color}
														color={variation.color}
														isProductView={true}
													/>
												</div>
											))}
										</div>
									</div>
								);
							}
							if (el.name === 'Матеріал') {
								return (
									<div key={el.name + i}>
										<div className='product-view__drawer-result-title-wrap'>
											<div className='product-view__drawer-result-title'>
												Доступні варіанти матеріалу
											</div>
											<img
												className='product-view__drawer-result-title-btn'
												onClick={() => handleIsOpenMenuVariations(false)}
												src={closeImgBlack}
												alt='img'
											/>
										</div>
										<div className='product-view__drawer-result-wrap'>
											{el.value.map((variation, index) => (
												<div
													onClick={() =>
														handleClickOpenProductVariations(
															{ name: el.name, value: variation },
															true
														)
													}
													key={variation.title + index}
												>
													<MaterialResult
														productMaterial={product.material}
														variation={variation}
														isHover={true}
														isVariationMenu={true}
													/>
												</div>
											))}
										</div>
									</div>
								);
							}
						})}
				</div>
			</Drawer>

			<Modal open={openModalForm} onClose={handleCloseModalForm}>
				<div className='product-view__modal-form-wrap'>
					<img
						className='product-view__modal-form-btn-close product-view__modal-form-btn-close--desktop'
						onClick={handleCloseModalForm}
						src={closeImg}
						alt='img'
					/>
					<div className='product-view__modal-form-text--wrap'>
						<div
							className='product-view__modal-form--wrap'
							onClick={handleCloseModalForm}
						></div>
						<div
							className='product-view__modal-text-wrap'
							onClick={handleCloseModalForm}
						>
							<div
								className='product-view__modal-form'
								onClick={(e) => e.stopPropagation()}
							>
								<div className='product-view__modal-form-title-wrap'>
									<div className='product-view__modal-form-title'>
										{isModaAdaptlForm
											? 'Адаптація виробу'
											: 'Запитання щодо товару'}
									</div>
									<button className='product-view__text-modal-tab-btn-close-wrap'>
										<svg
											className='product-view__text-modal-tab-btn-close'
											onClick={handleCloseModalForm}
											viewBox='0 0 14 14'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M1.27312 13.5269L0.473145 12.7269L6.20007 7L0.473145 1.27307L1.27312 0.473099L7.00004 6.20002L12.727 0.473099L13.5269 1.27307L7.80002 7L13.5269 12.7269L12.727 13.5269L7.00004 7.79997L1.27312 13.5269Z'
												fill='black'
											/>
										</svg>
									</button>
								</div>
								<div
									ref={openModalFormRef}
									className='product-view__modal-form-text-wrap'
								>
									<div className='product-view__modal-form-text'>
										{isModaAdaptlForm
											? "Ви можете змінити розмір, колір та матеріал даного виробу, напишіть ваші побажання і ми зв'яжемося для консультації"
											: 'Напишіть ваше питання щодо товару, ми звʼяжемося з вами для консультації'}
									</div>
									<div
										className={`product-view__form-input-wrap ${
											isSafari || isXiaomiRedmi
												? 'product-view__form-input-is-safari-wrap'
												: ''
										}`}
									>
										<div className='order-view__form-error-wrap'>
											<label
												className='product-view__form-input-label'
												htmlFor='productViewName'
											>
												Вкажіть ваше ім`я
											</label>
											<input
												className={`product-view__form-input ${
													isErrorName ? 'order-view__form-error' : ''
												}`}
												onChange={(e) => setName(e.target.value)}
												value={name}
												autoComplete='name'
												id='productViewName'
												name='name'
												type='text'
												placeholder=''
											/>
											{isErrorName && (
												<div className='order-view__form-error-text'>
													Обов'язкове поле
												</div>
											)}
										</div>

										<div
											className={`order-view__form-error-wrap ${
												isErrorPhone ? 'product-view__form-error--phone' : ''
											}`}
										>
											<label
												className='product-view__form-input-label'
												htmlFor='productViewPhone'
											>
												Вкажіть ваш телефон
											</label>
											<InputPhone
												phone={phone}
												setPhone={setPhone}
												isProductView={true}
												isErrorPhone={isErrorPhone}
											/>
											{isErrorPhone && (
												<div className='product-view__form-error-text--phone'>
													Обов'язкове поле
												</div>
											)}
										</div>

										<div className='order-view__form-error-wrap'>
											<label
												className='product-view__form-input-label'
												htmlFor='productViewEmail'
											>
												Вкажіть ваш email
											</label>
											<input
												className={`product-view__form-input ${
													isErrorEmail ? 'order-view__form-error' : ''
												}`}
												onChange={(e) => setEmail(e.target.value)}
												value={email}
												autoComplete='email'
												id='productViewEmail'
												name='email'
												type='text'
												placeholder=''
											/>
											{isErrorEmail && (
												<div className='order-view__form-error-text'>
													{!email.length
														? "Обов'язкове поле"
														: 'Вкажіть, будь ласка, коректний email'}{' '}
												</div>
											)}
										</div>

										<div className='order-view__form-error-wrap'>
											<label
												className='product-view__form-input-label'
												htmlFor='productViewWebsite'
											>
												Вкажіть місто для доставки
											</label>
											<input
												className={`product-view__form-input  ${
													isErrorAddress ? 'order-view__form-error' : ''
												}`}
												onChange={(e) => setAddress(e.target.value)}
												value={address}
												autoComplete='address'
												id='productViewWebsite'
												name='address'
												type='text'
												placeholder=''
											/>
											{isErrorAddress && (
												<div className='order-view__form-error-text'>
													Обов'язкове поле
												</div>
											)}
										</div>

										<div className='order-view__form-error-wrap'>
											<label
												className={`product-view__form-input-label ${
													isModaAdaptlForm
														? ''
														: 'product-view__form-input-label--not-adaptl-form'
												}`}
												htmlFor='productViewSocial'
											>
												{isModaAdaptlForm
													? 'Напишіть ваше питання та побажання'
													: 'Опишіть ваше питання'}
											</label>
											<textarea
												className={`product-view__form-input product-view__form-textarea ${
													isErrorDescription ? 'order-view__form-error' : ''
												}`}
												onChange={(e) => setDescription(e.target.value)}
												value={description}
												autoComplete='description'
												id='productViewSocial'
												name='description'
												rows={4}
												type='text'
												placeholder={`${
													isModaAdaptlForm
														? 'Якщо ви бажаєте адаптувати виріб під себе - вкажіть побажання щодо матеріалів, розмірів'
														: ''
												}`}
											/>
											{isErrorDescription && (
												<div className='order-view__form-error-text'>
													Обов'язкове поле
												</div>
											)}
										</div>

										<div className='product-view__form-input-label'>
											Кількість товару
										</div>
										<div className='product-view__form-count-wrap'>
											<button
												className='product-view__form-count-btn'
												onClick={() =>
													setProductCountForm(
														productCountForm - 1 === 0
															? 1
															: productCountForm - 1
													)
												}
											>
												-
											</button>
											<div className='product-view__form-count-text'>
												{productCountForm}
											</div>
											<button
												className='product-view__form-count-btn'
												onClick={() =>
													setProductCountForm(productCountForm + 1)
												}
											>
												+
											</button>
										</div>
										{(isErrorName ||
											isErrorDescription ||
											isErrorEmail ||
											isErrorPhone) && (
											<div className='contacts-view__form-error-banner product-view__form-error-banner'>
												{(isErrorName ||
													isErrorDescription ||
													isErrorPhone) && (
													<div className='contacts-view__form-error-banner-text'>
														Будь ласка, заповніть всі обов'язкові поля
													</div>
												)}
												{isErrorEmail &&
													((!isErrorName &&
														!isErrorDescription &&
														!isErrorPhone) ||
														!!email.length) && (
														<div className='contacts-view__form-error-banner-text'>
															{!email.length
																? "Будь ласка, заповніть всі обов'язкові поля"
																: 'Вкажіть, будь ласка, коректний email'}
														</div>
													)}
											</div>
										)}
										<button
											id='productViewSendBtnId'
											className='main-btn-4'
											onClick={handleSendForm}
										>
											Відправити запит
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			{!!product._id?.length && (
				<div itemScope='' itemType='http://schema.org/Product' className=''>
					<div className='product-view-wrap'>
						<BreadCrumbs product={product} />
					</div>

					<div className='product-view__product product-view-wrap'>
						<div
							className='product-view__product-swiper-wrap'
							ref={swiperWrapRef}
						>
							{!!product.images?.length ? (
								<>
									<div className='product-view__product-swiper-mob--wrap'>
										<Swiper
											ref={swiperRef}
											onSwiper={setSwiper}
											loop={true}
											spaceBetween={windowWidth < 600 ? 0 : 10}
											navigation={true}
											zoom={{ maxRatio: 5 }}
											thumbs={{ swiper: thumbsSwiper }}
											modules={[Zoom, Navigation, Thumbs]}
											className={`product-view__product-swiper-2-wrap ${
												!!videoPlay?.length
													? 'product-view__product-swiper-2-wrap--opacity'
													: ''
											}`}
											speed={450}
											onRealIndexChange={handleSlideChangeTransitionStart2}
											threshold={5}
										>
											{product.images.map((img, i) => (
												<SwiperSlide key={img + i}>
													<div
														className='product-view__product-slider-2-zoom-wrap'
														onClick={() => handleOpenModalZoom(i)}
													>
														<img
															className='product-view__product-slider-2-zoom-img'
															src={img}
															alt={product.title}
															itemProp='image'
														/>
														<meta itemProp='image' content={img} />
													</div>
												</SwiperSlide>
											))}
											{!!product?.mark?.text?.length && (
												<div
													className='product-view__product-slider-2-mark-wrap'
													style={{
														background: `${
															!!product?.mark?.background?.length
																? product.mark.background
																: '#19191D'
														}`,
													}}
												>
													{product.mark.text}
												</div>
											)}
											<button
												className={`product-view__product-swiper-2-btn product-view__product-swiper-2-btn-prew`}
												onClick={handleNextSleder}
											>
												<div className='product-view__product-swiper-2-btn-arrow-wrap'>
													<img
														className='product-view__product-swiper-1-btn-1'
														src={arrow5}
														alt='img'
													/>
												</div>
											</button>
											<button
												className={`product-view__product-swiper-2-btn product-view__product-swiper-2-btn-next`}
												onClick={handlePrevSleder}
											>
												<div className='product-view__product-swiper-2-btn-arrow-wrap'>
													<img
														className='product-view__product-swiper-1-btn-2'
														src={arrow5}
														alt='img'
													/>
												</div>
											</button>
										</Swiper>
										{!!videoPlay?.length && (
											<div className='product-view__product-video-wrap'>
												<div className='product-view__product-video--wrap'>
													{isMobile ? (
														<Modal
															open={openVideoModalForm}
															onClose={handleVideoClose}
														>
															<div className='product-video-modal'>
																<ReactPlayer
																	className='product-video-modal-player'
																	controls={true}
																	url={
																		videoPlay +
																		`&showinfo=0&enablejsapi=1&origin=${window.location.origin}`
																	}
																	config={{
																		youtube: {
																			playerVars: {
																				modestbranding: 1,
																				rel: 0,
																				showinfo: 0,
																			},
																			embedOptions: {
																				host: 'https://www.youtube.com',
																				embedPath: '/embed/',
																			},
																			preload: false,
																			xhrContext: {
																				headers: {
																					'Access-Control-Allow-Origin': '*',
																				},
																			},
																		},
																	}}
																	preload='metadata'
																/>
																<img
																	className='product-video-modal-close-img'
																	onClick={handleVideoClose}
																	src={closeImgBlack}
																	alt='img'
																/>
															</div>
														</Modal>
													) : (
														<ReactPlayer
															className='product-view__product-slider-video-2-zoom-wrap'
															controls={true}
															width='100%'
															height='100%'
															url={
																videoPlay +
																`&showinfo=0&enablejsapi=1&origin=${window.location.origin}`
															}
															config={{
																youtube: {
																	playerVars: {
																		modestbranding: 1,
																		rel: 0,
																		showinfo: 0,
																	},
																	embedOptions: {
																		host: 'https://www.youtube.com',
																		embedPath: '/embed/',
																	},
																	preload: false,
																	xhrContext: {
																		headers: {
																			'Access-Control-Allow-Origin': '*',
																		},
																	},
																},
															}}
															preload='metadata'
														/>
													)}
													<button
														className={`product-view__product-swiper-2-btn product-view__product-swiper-2-btn-prew`}
														onClick={handleNextSleder}
													>
														<div className='product-view__product-swiper-2-btn-arrow-wrap'>
															<img
																className='product-view__product-swiper-1-btn-1'
																src={arrow5}
																alt='img'
															/>
														</div>
													</button>
													<button
														className={`product-view__product-swiper-2-btn product-view__product-swiper-2-btn-next`}
														onClick={handlePrevSleder}
													>
														<div className='product-view__product-swiper-2-btn-arrow-wrap'>
															<img
																className='product-view__product-swiper-1-btn-2'
																src={arrow5}
																alt='img'
															/>
														</div>
													</button>
												</div>
											</div>
										)}
										<div className='product-view__product-swiper-pagination-wrap'>
											<PaginationSwiper
												paginationArr={product.images}
												swiperActiveIndex={swiperActiveIndex}
											/>
										</div>
									</div>
								</>
							) : (
								<div className='product-view__product-swiper-img-none-wrap'>
									{!!videoPlay?.length && (
										<div className='product-view__product-video-wrap'>
											<div className='product-view__product-video--wrap'>
												{isMobile ? (
													<Modal
														open={openVideoModalForm}
														onClose={handleVideoClose}
													>
														<div className='product-video-modal'>
															<ReactPlayer
																className='product-video-modal-player'
																controls={true}
																url={
																	videoPlay +
																	`&showinfo=0&enablejsapi=1&origin=${window.location.origin}`
																}
																config={{
																	youtube: {
																		playerVars: {
																			modestbranding: 1,
																			rel: 0,
																			showinfo: 0,
																		},
																		embedOptions: {
																			host: 'https://www.youtube.com',
																			embedPath: '/embed/',
																		},
																		preload: false,
																		xhrContext: {
																			headers: {
																				'Access-Control-Allow-Origin': '*',
																			},
																		},
																	},
																}}
																preload='metadata'
															/>
															<img
																className='product-video-modal-close-img'
																onClick={handleVideoClose}
																src={closeImgBlack}
																alt='img'
															/>
														</div>
													</Modal>
												) : (
													<ReactPlayer
														className='product-view__product-slider-video-2-zoom-wrap'
														controls={true}
														width='100%'
														height='100%'
														url={
															videoPlay +
															`&showinfo=0&enablejsapi=1&origin=${window.location.origin}`
														}
														config={{
															youtube: {
																playerVars: {
																	modestbranding: 1,
																	rel: 0,
																	showinfo: 0,
																},
																embedOptions: {
																	host: 'https://www.youtube.com',
																	embedPath: '/embed/',
																},
																preload: false,
																xhrContext: {
																	headers: {
																		'Access-Control-Allow-Origin': '*',
																	},
																},
															},
														}}
														preload='metadata'
													/>
												)}
											</div>
										</div>
									)}

									<img
										className={`product-view__product-swiper-img-none ${
											!!videoPlay?.length
												? 'product-view__product-swiper-2-wrap--opacity'
												: ''
										}`}
										src={noPhotos}
										alt='img'
									/>
								</div>
							)}
							<div className='product-view__product-swiper-1--wrap'>
								{!!product.images?.length && (
									<div className='product-view__product-swiper-1-btn-wrap'>
										<button
											className={`product-view__product-swiper-1-btn ${
												handleIsDisplayBtn() ? 'displayNone' : ''
											}`}
											onClick={handleNextSleder}
										>
											<div className='product-view__product-swiper-1-btn-1-wrap'>
												<img
													className='product-view__product-swiper-1-btn-1'
													src={arrow5}
													alt='img'
												/>
											</div>
										</button>
										<Swiper
											ref={swiper__1Ref}
											onSwiper={setThumbsSwiper}
											direction={windowWidth > 960 ? 'vertical' : 'horizontal'}
											loop={true}
											spaceBetween={20}
											slidesPerView={
												windowWidth > 960
													? Math.floor(handleHeightWrap() / 95) <= 1
														? 1
														: Math.floor(handleHeightWrap() / 95)
													: Math.floor(handleWidthWrap() / 100) <= 1
													? 1
													: Math.floor(handleWidthWrap() / 100)
											}
											mousewheel={true}
											navigation={true}
											modules={[Navigation, Thumbs]}
											className='product-view__product-swiper-1-wrap'
											style={{
												height: `${
													windowWidth > 960
														? Math.floor(handleHeightWrap() / 95) * 95 - 20 >=
														  95 * product.images.length
															? 95 * product.images.length
															: Math.floor(handleHeightWrap() / 95) * 95 - 20
														: 80
												}px`,
												width: `${
													windowWidth > 960
														? 75
														: Math.floor(handleWidthWrap() / 100) * 100 - 20 >=
														  100 * product.images.length
														? 100 * product.images.length
														: Math.floor(handleWidthWrap() / 100) * 100 - 20
												}px`,
											}}
										>
											<>
												{product.images.map((img, index) => (
													<SwiperSlide
														onClick={() => setVideoPlay('')}
														key={img + index}
													>
														<img src={img} alt={product.title} />
													</SwiperSlide>
												))}
											</>
										</Swiper>
										<button
											className={`product-view__product-swiper-1-btn product-view__product-swiper-1-btn--end ${
												handleIsDisplayBtn() ? 'displayNone' : ''
											}`}
											onClick={handlePrevSleder}
										>
											<div className='product-view__product-swiper-1-btn-2-wrap'>
												<img
													className='product-view__product-swiper-1-btn-2'
													src={arrow5}
													alt='img'
												/>
											</div>
										</button>
									</div>
								)}
								{!!product.video_link_1?.length && (
									<div className='product-view__product-swiper-1-video-wrap'>
										<ReactPlayer
											className={`product-view__product-swiper-1-video ${
												product.video_link_1.includes('shorts/')
													? 'product-view__product-swiper-1-video--shorts'
													: ''
											}`}
											width={`${
												windowWidth < 600 ? 65 : windowWidth >= 960 ? 75 : 78.4
											}px`}
											height={`${
												windowWidth < 600 ? 65 : windowWidth >= 960 ? 75 : 78.4
											}px`}
											url={product.video_link_1}
											light={true}
											config={{
												youtube: {
													playerVars: {
														modestbranding: 1,
														rel: 0,
														showinfo: 0,
													},
													embedOptions: {
														host: 'https://www.youtube.com',
														embedPath: '/embed/',
													},
													preload: false,
													xhrContext: {
														headers: { 'Access-Control-Allow-Origin': '*' },
													},
												},
											}}
											preload='metadata'
										/>
										<div className='product-view__product-swiper-1-video-text--video'>
											Відео
										</div>
										<div
											className='product-view__product-swiper-1-video-block'
											onClick={() => openVideoModal(product.video_link_1)}
										>
											<img
												className='product-view__product-swiper-1-video-block-img'
												src={playCircle}
												alt='img'
											/>
										</div>
									</div>
								)}
								{!!product.video_link_2?.length && (
									<div className='product-view__product-swiper-1-video-wrap'>
										<ReactPlayer
											className={`product-view__product-swiper-1-video ${
												product.video_link_2.includes('shorts/')
													? 'product-view__product-swiper-1-video--shorts'
													: ''
											}`}
											width={`${
												windowWidth < 600 ? 65 : windowWidth >= 960 ? 75 : 78.4
											}px`}
											height={`${
												windowWidth < 600 ? 65 : windowWidth >= 960 ? 75 : 78.4
											}px`}
											url={product.video_link_2}
											light={true}
											config={{
												youtube: {
													playerVars: {
														modestbranding: 1,
														rel: 0,
														showinfo: 0,
													},
													embedOptions: {
														host: 'https://www.youtube.com',
														embedPath: '/embed/',
													},
													preload: false,
													xhrContext: {
														headers: { 'Access-Control-Allow-Origin': '*' },
													},
												},
											}}
											preload='metadata'
										/>
										<div className='product-view__product-swiper-1-video-text--video'>
											Відео
										</div>
										<div
											className='product-view__product-swiper-1-video-block'
											onClick={() => openVideoModal(product.video_link_2)}
										>
											<img
												className='product-view__product-swiper-1-video-block-img'
												src={playCircle}
												alt='img'
											/>
										</div>
									</div>
								)}
								{!!product.model_3d?.length && (
									<a
										className='product-view__product-swiper-model-wrap'
										href={product.model_3d}
										target='_blank'
									>
										<div className='product-view__product-swiper-model'>
											<img
												className='product-view__product-swiper-model-img'
												src={square}
												alt='img'
											/>
										</div>
										<div className='product-view__product-swiper-1-video-text'>
											3D модель
										</div>
									</a>
								)}
							</div>
						</div>
						<div className='product-view__product-text-wrap'>
							<h1 className='product-view__product-text-title' itemProp='name'>
								{product.title}
							</h1>
							<meta itemProp='name' content={product.title}></meta>
							<h2 className='product-view__product-text-code'>
								<meta itemProp='sku' content={product.code} />
								Код товару: {product.code}
							</h2>
							{/* <div className='product-view__product-text-sub-title'>
								<link
									itemProp='availability'
									href='http://schema.org/InStock'
								/>
								<meta
									itemProp='brand'
									content={
										!!product.vendor_name?.length
											? product.vendor_name
											: !!vendor.company_name?.length
											? vendor.company_name
											: ''
									}
								/>
								Виробник:&nbsp;
								<span
									className='product-view__product-text-vendor'
									onClick={() =>
										vendorRef.current.scrollIntoView({ behavior: 'smooth' })
									}
								>
									{!!product.vendor_name?.length
										? product.vendor_name
										: !!vendor.company_name?.length
										? vendor.company_name
										: ''}
								</span>
							</div> */}
							{/* <Box
                                    sx={{
                                        width: 200,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    className='product-view__rating-wrap'
                                >
                                    <Rating
                                        className='product-view__rating'
                                        size="large"
                                        name="simple-controlled"
                                        precision={0.5}
                                        value={rating}
                                        onChange={(event, newValue) => handleRating(event, newValue)}
                                    />
                                    <div className='product-view__reviews'>22 Відгуки</div>
                                </Box> */}

							<div
								className='product-view__product-text-price'
								itemProp='offers'
								itemScope=''
								itemType='https://schema.org/Offer'
							>
								<meta itemProp='priceCurrency' content='UAH' />
								<meta itemProp='price' content={product.price} />
								<span itemProp='price'>
									{handlePriceReplace(product.price)}
								</span>
								<span itemProp='priceCurrency' content='UAH'>
									грн
								</span>
								<span>
									{product.retail_type?.length ? product.retail_type : ''}
								</span>
							</div>
							{!!product.production_time?.length && (
								<div className='product-view__product-text-time'>
									{product.production_time}
								</div>
							)}

							{!!product.variations?.length && (
								<VariationsCard
									product={product}
									modalScrollPosition={modalScrollPosition}
									setModalScrollPosition={setModalScrollPosition}
									handleClickOpenProductVariations={
										handleClickOpenProductVariations
									}
									handleIsOpenMenuVariations={handleIsOpenMenuVariations}
								/>
							)}

							<div className='product-view__product-text-buy-btn-wrap'>
								<div className='product-view__product-text-buy-btn-count-wrap'>
									<button
										className='product-view__product-text-buy-btn-count product-view__product-text-buy-btn-count-del'
										onClick={() =>
											setProductCount(
												productCount - 1 === 0 ? 1 : productCount - 1
											)
										}
										name='decrease-products'
										aria-label='decrease-products'
									></button>
									<span>{productCount}</span>
									<button
										className='product-view__product-text-buy-btn-count'
										onClick={() => setProductCount(productCount + 1)}
										name='increase-products'
										aria-label='increase-products'
									>
										+
									</button>
								</div>
								<button
									className='product-view__like-btn-wrap'
									onClick={() => handleLike(product)}
								>
									{!isLiked ? (
										<img
											className={`product-view__like-btn`}
											src={likeEllipse}
											alt='img'
										/>
									) : (
										<img
											className={`product-view__like-btn product-view__like-btn--is-liked`}
											src={likeEllipseHover}
											alt='img'
										/>
									)}
								</button>
								<button
									className='main-btn-1 product-view__product-text-buy'
									onClick={handleAddShoppingProducts}
								>
									Додати в кошик
								</button>
							</div>

							<div
								className='product-view__product-text-request-btn-wrap'
								ref={formWrap}
							>
								<button
									className='product-view__product-text-request-btn'
									onClick={handleOpenModalForm}
								>
									<div className='product-view__product-text-request-btn-text-wrap'>
										<img
											className='product-view__product-text-request-btn-text-img'
											src={textImg}
											alt='img'
										/>
										<img
											className='product-view__product-text-request-btn-text-img--green'
											src={textImgGreen}
											alt='img'
										/>
										<div className='product-view__product-text-request-btn-text'>
											Задати питання
										</div>
									</div>
									<img
										className='product-view__product-text-request-btn-text-img-arrow'
										src={arrow2}
										alt='img'
									/>
									<img
										className='product-view__product-text-request-btn-text-img-arrow--green'
										src={arrow2Green}
										alt='img'
									/>
								</button>
								{product.type === 'individual' && (
									<button
										className='product-view__product-text-request-btn product-view__product-text-request-btn--border'
										onClick={handleAdaptForm}
									>
										<div className='product-view__product-text-request-btn-text-wrap'>
											<img
												className='product-view__product-text-request-btn-text-img'
												src={brush}
												alt='img'
											/>
											<img
												className='product-view__product-text-request-btn-text-img--green'
												src={brushGreen}
												alt='img'
											/>
											<div className='product-view__product-text-request-btn-text'>
												Адаптуй виріб під себе
											</div>
										</div>
										<img
											className='product-view__product-text-request-btn-text-img-arrow'
											src={arrow2}
											alt='img'
										/>
										<img
											className='product-view__product-text-request-btn-text-img-arrow--green'
											src={arrow2Green}
											alt='img'
										/>
									</button>
								)}
							</div>

							{/* Тимчасово приховати 3D модель  */}
							{/* {
                                    !!product.model_3d?.length && 
                                        <div className="product-view__product-model-wrap">
                                            <div className="product-view__product-model-title-wrap">
                                                <div className="product-view__product-model-title">Завантажити 3D модель</div>
                                                <div className="product-view__product-model-text">Ви можете скачати 3D модель виробу. <a className="product-view__product-model-link" href={product.model_3d} target='_blank'>Завантажити модель</a></div>
                                            </div>
                                        </div>
                                } */}
						</div>
					</div>

					<div className='product-view-wrap'>
						<div className='product-view__text-mobile-tab-wrap'>
							<button
								className='product-view__text-mobile-tab-btn'
								onClick={() => handleOpenProductInfo(1)}
							>
								<div
									className='product-view__text-mobile-tab-btn-text'
									itemProp='description'
								>
									Характеристики
								</div>
								<img
									className='product-view__text-mobile-tab-btn-text-img-arrow'
									src={arrow2}
									alt='img'
								/>
							</button>
							<button
								className='product-view__text-mobile-tab-btn'
								onClick={() => handleOpenProductInfo(2)}
							>
								<div className='product-view__text-mobile-tab-btn-text'>
									Доставка та оплата
								</div>
								<img
									className='product-view__text-mobile-tab-btn-text-img-arrow'
									src={arrow2}
									alt='img'
								/>
							</button>
							<button
								className='product-view__text-mobile-tab-btn'
								onClick={() => handleOpenProductInfo(3)}
							>
								<div className='product-view__text-mobile-tab-btn-text'>
									Опис
								</div>
								<img
									className='product-view__text-mobile-tab-btn-text-img-arrow'
									src={arrow2}
									alt='img'
								/>
							</button>
						</div>

						<div className='product-view__text-tab--wrap'>
							<div className='product-view__text-tab-wrap'>
								<button
									className={`product-view__text-tab ${
										tab === 1 ? 'product-view__text-tab-active' : ''
									}`}
									onClick={() => setTab(1)}
								>
									Характеристики
								</button>
								<button
									className={`product-view__text-tab ${
										tab === 2 ? 'product-view__text-tab-active' : ''
									}`}
									onClick={() => setTab(2)}
								>
									Доставка та оплата
								</button>
								<button
									className={`product-view__text-tab ${
										tab === 3 ? 'product-view__text-tab-active' : ''
									}`}
									onClick={() => setTab(3)}
								>
									Опис
								</button>
							</div>

							{tab === 1 && (
								<div className='product-view__text-tab-1'>
									<div className='product-view__text-tab-1-title'>
										Про виріб
									</div>
									<div>
										Тип виробництва:{' '}
										<span className='product-view__text-tab-1-value'>
											{product.type === 'serial' ? 'Серійне' : 'Індивідуальне'}
										</span>
									</div>
									<div>
										Бренд:{' '}
										<span className='product-view__text-tab-1-value'>
											{!!product.vendor_name?.length
												? product.vendor_name
												: !!vendor.company_name?.length
												? vendor.company_name
												: ''}
										</span>
									</div>
									<div>
										Категорія:{' '}
										<span className='product-view__text-tab-1-value'>
											{product.category_name}
										</span>
									</div>
									{!!product.material?.title?.length && (
										<div>
											Матеріал:{' '}
											<span className='product-view__text-tab-1-value'>
												{product.material?.title}
											</span>
										</div>
									)}
									{!!standardColors?.length ? (
										<>
											{!!product.color_name?.length && (
												<div>
													Колір:{' '}
													<span className='product-view__text-tab-1-value'>
														{product.color_name}
													</span>
												</div>
											)}
										</>
									) : (
										<>
											{!!product.color_filter?.length && (
												<div>
													Колір:{' '}
													<span className='product-view__text-tab-1-value'>
														{
															standardColors.find(
																(el) => el.value === product.color_filter
															).name
														}
													</span>
												</div>
											)}
										</>
									)}
									{!!product.characteristics?.length &&
										!!handleCheckCharacteristics(product.characteristics)
											?.length &&
										handleCheckCharacteristics(product.characteristics).map(
											(el, i) => (
												<div key={el.name + i}>
													{el.name}:{' '}
													<span className='product-view__text-tab-1-value'>
														{el.value}
													</span>
												</div>
											)
										)}
								</div>
							)}
							{tab === 3 && (
								<div className='product-view__text-tab-3'>
									<pre className='product-view__product-text-description'>
										<meta
											itemProp='description'
											content={product.description}
										/>
										<meta
											itemProp='url'
											content={`${process.env.REACT_APP_BASE_URL}/${location.pathname}`}
										/>
										{product.description}
									</pre>
								</div>
							)}
							{tab === 2 && (
								<div className='product-view__text-tab-2'>
									<div>
										<div className='product-view__text-tab-1-title'>
											Варіанти доставки:
										</div>
										{!!vendor.conditions_delivery?.length && (
											<div className='product-view__text-tab-1-conditions_delivery'>
												{vendor.conditions_delivery}
											</div>
										)}
										<ul className='product-view__text-tab-2-ul'>
											{!!vendor.delivery_options?.length &&
												vendor.delivery_options.map((el, i) => (
													<li
														className='product-view__text-tab-2-li'
														key={el + i}
													>
														{el}
													</li>
												))}
										</ul>
									</div>
									<div>
										<div className='product-view__text-tab-1-title'>
											Варіанти оплати:
										</div>
										{!!vendor.conditions_payment?.length && (
											<div className='product-view__text-tab-1-conditions_delivery'>
												{vendor.conditions_payment}
											</div>
										)}
										<ul className='product-view__text-tab-2-ul'>
											{!!vendor.payment_options?.length &&
												vendor.payment_options.map((el, i) => (
													<li
														className='product-view__text-tab-2-li'
														key={el + i}
													>
														{el}
													</li>
												))}
										</ul>
									</div>
								</div>
							)}
						</div>
					</div>

					{/* Temporarily hidden block with vendor */}

					{/* {!!vendor?._id?.length && (
						<>
							<div className='product-view__vendor-line-top' />
							<div
								className='product-view__vendor product-view-wrap'
								ref={vendorRef}
							>
								<div className='product-view__vendor-info-description-name'>
									Про бренд {vendor.company_name}
								</div>
								<div className='product-view__vendor-info-wrap'>
									<div className='product-view__vendor-info-logo-wrap'>
										<img
											className='product-view__vendor-info-logo'
											src={
												!!vendor.logo_image?.length
													? vendor.logo_image
													: noPhotos
											}
											alt={vendor.company_name}
										/>
										{/* Тимчасово приховати Rating  */}
					{/* <div className='product-view__vendor-info-logo-text-wrap'>
                                                    <div className='product-view__vendor-info-logo-number'>4.6</div>
                                                    <Box
                                                        sx={{
                                                            width: 200,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        className='product-view__vendor-info-logo-rating-wrap'
                                                    >
                                                        <Rating
                                                            className='product-view__vendor-info-logo-rating'
                                                            size="large"
                                                            name="simple-controlled"
                                                            precision={0.5}
                                                            value={rating}
                                                            onChange={(event, newValue) => handleRating(event, newValue)}
                                                        />
                                                    </Box>
                                                    <div className="product-view__vendor-info-logo-text">22 Відгуки</div>
                                                </div> */}
					{/* </div>
									<div className='product-view__vendor-info-description-wrap'>
										<div
											className='product-view__vendor-info-description'
											style={{
												whiteSpace: 'pre-wrap',
												overflow: 'hidden',
												display: '-webkit-box',
												WebkitLineClamp: isAllDescription ? 'unset' : 6,
												WebkitBoxOrient: 'vertical',
											}}
											ref={containerVendorRef}
										>
											{vendor.description}
										</div>
										{containerVendorRef &&
											containerVendorRef?.current?.clientHeight >= 268 && (
												<button
													className='product-view__vendor-info-description-btn'
													onClick={() => setIsAllDescription(!isAllDescription)}
												>
													{isAllDescription
														? 'Згорнути'
														: 'Переглянути повністю'}
												</button>
											)}
										<NavLink
											className='product-view__vendor-info-description-link'
											to={`/vendor/${
												vendor.alias?.length ? vendor.alias : vendor._id
											}`}
										>
											Сторінка виробника
										</NavLink>
									</div>
								</div>
							</div>
						</> 
					)} */}

					{!!categoryProducts?.length && (
						<>
							<div className='product-view__vendor-line-bottom' />
							<div className='product-view-wrap'>
								<div className='product-view__cards-title-wrap'>
									<div className='product-view__cards-title'>
										Вас також можуть зацікавити
									</div>
									<div>
										<NavLink
											className='product-view__cards-catalog-btn'
											to={handleGoCatalog()}
											onClick={() =>
												handleRefreshSavedScroll(handleGoCatalog())
											}
										>
											В каталог
										</NavLink>
									</div>
								</div>
								<div className='product-view__cards'>
									{categoryProducts
										// .filter(
										// 	(ell) => location.pathname !== handleCheckShortId(ell)
										// )
										.map((ell, i) => (
											<div
												className='product-view__card-wrap'
												key={ell._id + i}
											>
												<ProductCard
													onCardClick={() => setProduct({})}
													product={ell}
													handleNextVariation={handleNextVariation}
												/>
											</div>
										))}
								</div>
								{handlePageCount(productsCount) > page + 1 && (
									<div
										className='product-view__products-pagination'
										onClick={() => setPage(page + 1)}
									>
										<button className='main-btn-2'>Показати більше</button>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
}

export default ProductView;
