import './PartnerRegistrationView.css';
import { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import deleteImg3 from '../../assets/deleteImg3.svg';
import { useDispatch, useSelector } from 'react-redux';
import InputPhone from '../../components/InputPhone/InputPhone';
import { fetchGetData, validEmail } from '../../helpers/Utils';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import { setIsSuccessFormPartnersView } from '../../store/homeSlice';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function PartnerRegistrationView() {
	const seo = useSelector((state) => state.homeSlice.seo);
	const utm_source = useSelector((state) => state.homeSlice.utm_source);
	const utm_medium = useSelector((state) => state.homeSlice.utm_medium);
	const utm_campaign = useSelector((state) => state.homeSlice.utm_campaign);
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [companyCity, setCompanyCity] = useState('');
	const [companyAdressa, setCompanyAdressa] = useState('');
	const [companyPhone, setCompanyPhone] = useState('');
	const [companyEmail, setCompanyEmail] = useState('');
	const [companyContact, setCompanyContact] = useState('');
	const [companyWork, setCompanyWork] = useState('');
	const [companySocial, setCompanySocial] = useState('');
	const [companyLink, setCompanyLink] = useState('');
	const [companyInfo, setCompanyInfo] = useState('');
	const [companyProducts, setCompanyProducts] = useState('');
	const [companyPayment, setCompanyPayment] = useState([]);
	const [companyNewPayment, setCompanyNewPayment] = useState('');
	const [companyDelivery, setCompanyDelivery] = useState([]);
	const [companyNewDelivery, setCompanyNewDelivery] = useState('');
	const [imageLogoFiles, setImageLogoFiles] = useState({});
	const [imageBannerFiles, setImageBannerFiles] = useState({});
	const [imageDocFilesArr, setImageDocFilesArr] = useState([]);
	const [slogan, setSlogan] = useState('');
	const [companyLegalName, setCompanyLegalName] = useState('');
	const [conditionsPayment, setConditionsPayment] = useState('');
	const [conditionsDelivery, setConditionsDelivery] = useState('');
	const [alias, setAlias] = useState('');
	const [step, setStep] = useState(1);
	const [isErrorName, setIsErrorName] = useState(false);
	const [isErrorPhone, setIsErrorPhone] = useState(false);
	const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
	const [isErrorCompanyCity, setIsErrorCompanyCity] = useState(false);
	const [isErrorCompanyAdressa, setIsErrorCompanyAdressa] = useState(false);
	const [isErrorCompanyPhone, setIsErrorCompanyPhone] = useState(false);
	const [isErrorCompanyEmail, setIsErrorCompanyEmail] = useState(false);
	const [isErrorCompanyContact, setIsErrorCompanyContact] = useState(false);
	const [isErrorSlogan, setIsErrorSlogan] = useState(false);
	const [isErrorCompanyWork, setIsErrorCompanyWork] = useState(false);
	const [isErrorCompanyLegalName, setIsErrorCompanyLegalName] = useState(false);
	const [isErrorCompanyInfo, setIsErrorCompanyInfo] = useState(false);
	const [isErrorCompanyProducts, setIsErrorCompanyProducts] = useState(false);
	const [isErrorImageLogoFiles, setIsErrorImageLogoFiles] = useState(false);
	const [isErrorCompanyPayment, setIsErrorCompanyPayment] = useState(false);
	const [isErrorConditionsDelivery, setIsErrorConditionsDelivery] =
		useState(false);
	const [isErrorAlias, setIsErrorAlias] = useState(false);
	const [isErrorDocFiles, setIsErrorDocFiles] = useState(false);
	const transliterator = new cyrillicToTranslit({ preset: 'uk' });
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const handleSetCompanyName = (str) => {
		setCompanyName(str);
		let res = str.replace(/[^a-zA-Zа-яА-ЯіІїЇєЄ\s]/g, '');
		if (res?.length) {
			const latinText = transliterator
				.transform(res)
				.trim()
				.split(' ')
				.join('-');
			return setAlias(latinText.toLowerCase());
		}
	};

	const handleNextStep = () => {
		if (!name?.length || !phone?.length || phone.length < 12) {
			setIsErrorName(!name?.length ? true : false);
			setIsErrorPhone(!phone?.length || phone.length < 12 ? true : false);
		} else {
			setStep(2);
			setIsErrorName(false);
			setIsErrorPhone(false);
		}
	};

	const handleDeleteDocFiles = (name) => {
		setImageDocFilesArr([...imageDocFilesArr.filter((el) => el.name !== name)]);
	};

	const handleDelPayment = (str) => {
		setCompanyPayment([...companyPayment.filter((el) => el !== str)]);
	};

	const handleAddCompanyNewPayment = () => {
		if (companyNewPayment?.length) {
			setCompanyPayment([...companyPayment, companyNewPayment]);
			setCompanyNewPayment('');
		}
	};

	const handleDelDelivery = (str) => {
		setCompanyDelivery([...companyDelivery.filter((el) => el !== str)]);
	};

	const handleAddCompanyNewDelivery = () => {
		if (companyNewDelivery?.length) {
			setCompanyDelivery([...companyDelivery, companyNewDelivery]);
			setCompanyNewDelivery('');
		}
	};

	const handleUploudDocFiles = (obj) => {
		if (obj.name && imageDocFilesArr?.length) {
			setImageDocFilesArr([...imageDocFilesArr, obj]);
			return;
		}
		if (obj.name && !imageDocFilesArr?.length) {
			setImageDocFilesArr([obj]);
		}
	};

	const hendleClean = () => {
		setName('');
		setPhone('');
		setCompanyName('');
		setCompanyCity('');
		setCompanyAdressa('');
		setCompanyPhone('');
		setCompanyEmail('');
		setCompanyContact('');
		setCompanyWork('');
		setCompanySocial('');
		setCompanyLink('');
		setCompanyInfo('');
		setCompanyProducts('');
		setCompanyPayment([]);
		setCompanyDelivery([]);
		setCompanyNewDelivery('');
		setCompanyNewPayment('');
		setConditionsPayment('');
		setConditionsDelivery('');
		setAlias('');
		setImageLogoFiles({});
		setImageBannerFiles({});
		setImageDocFilesArr({});
	};

	const handleIsValidCustomId = async (strAlias) => {
		await fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/vendors/check?alias=${strAlias}`
		).then((res) => {
			if (res?.success) {
				return true;
			} else {
				console.log('GET PartnersView', res);
				return false;
			}
		});
	};

	const hendleSend = () => {
		if (
			name?.length &&
			alias?.length &&
			handleIsValidCustomId(alias) &&
			imageDocFilesArr?.length &&
			companyDelivery?.length &&
			companyPayment?.length &&
			imageLogoFiles?.name?.length &&
			companyProducts?.length &&
			companyInfo &&
			companyLegalName?.length &&
			companyWork?.length &&
			slogan?.length &&
			companyContact?.length &&
			companyEmail?.length &&
			validEmail(companyEmail) &&
			phone?.length &&
			phone.length >= 12 &&
			companyPhone?.length &&
			companyPhone.length >= 12 &&
			companyName?.length &&
			companyCity?.length &&
			companyAdressa?.length
		) {
			let formData = new FormData();
			formData.append('contact_name', name);
			formData.append('alias', alias);
			formData.append('contact_phone', phone);
			formData.append('company_name', companyName);
			formData.append('city', companyCity);
			formData.append('address', companyAdressa);
			formData.append('company_phone', companyPhone);
			formData.append('email', companyEmail);
			formData.append('company_owner_name', companyContact);
			formData.append('work_schedule', companyWork);
			formData.append('company_social_networks', companySocial);
			formData.append('company_website', companyLink);
			formData.append('description', companyInfo);
			formData.append('company_legal_name', companyLegalName);
			formData.append('company_products', companyProducts);
			formData.append('fileLogo', imageLogoFiles);
			formData.append('fileBackground', imageBannerFiles);
			formData.append('nameofpage', location);
			formData.append('conditions_payment', conditionsPayment);
			formData.append('conditions_delivery', conditionsDelivery);

			if (companyPayment.length) {
				companyPayment.map((el) => {
					formData.append('payment_options[]', el);
				});
			} else {
				formData.append('payment_options[]', []);
			}
			if (companyDelivery.length) {
				companyDelivery.map((el) => {
					formData.append('delivery_options[]', el);
				});
			} else {
				formData.append('delivery_options[]', []);
			}
			if (imageDocFilesArr.length) {
				imageDocFilesArr.map((el) => {
					formData.append('fileDocs', el);
				});
			} else {
				formData.append('fileDocs', []);
			}
			formData.append('slogan', slogan);
			formData.append('utm_source', utm_source?.length ? utm_source : null);
			formData.append('utm_medium', utm_medium?.length ? utm_medium : null);
			formData.append(
				'utm_campaign',
				utm_campaign?.length ? utm_campaign : null
			);
			fetch(`${process.env.REACT_APP_BASE_URL}/forms/company_partner`, {
				method: 'POST',
				body: formData,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.success && res.data) {
						dispatch(setIsSuccessFormPartnersView(true));
						navigate('/success');
					} else {
						console.log('POST PartnersView:', res);
					}
				});

			hendleClean();
		} else {
			setIsErrorAlias(
				!alias?.length || (alias?.length && !handleIsValidCustomId(alias))
					? true
					: false
			);
			setIsErrorDocFiles(!imageDocFilesArr.length ? true : false);
			setIsErrorImageLogoFiles(!imageLogoFiles?.name?.length ? true : false);
			setIsErrorCompanyName(!companyName?.length ? true : false);
			setIsErrorCompanyCity(!companyCity?.length ? true : false);
			setIsErrorSlogan(!slogan?.length ? true : false);
			setIsErrorCompanyPayment(!companyPayment?.length ? true : false);
			setIsErrorCompanyLegalName(!companyLegalName?.length ? true : false);
			setIsErrorCompanyWork(!companyWork?.length ? true : false);
			setIsErrorConditionsDelivery(!companyDelivery?.length ? true : false);
			setIsErrorCompanyInfo(!companyInfo?.length ? true : false);
			setIsErrorCompanyProducts(!companyProducts?.length ? true : false);
			setIsErrorCompanyAdressa(!companyAdressa?.length ? true : false);
			setIsErrorCompanyPhone(
				!companyPhone?.length || companyPhone.length < 12 ? true : false
			);
			setIsErrorCompanyContact(!companyContact?.length ? true : false);
			setIsErrorCompanyEmail(
				!companyEmail?.length || !validEmail(companyEmail) ? true : false
			);
		}
	};

	return (
		<div className='partner-registration'>
			<SeoBlock
				title={seo.title_registration}
				description={seo.description_registration}
				keywords={seo.keywords_registration}
			/>
			<h3 className='partner-registration__title'>
				<h1>Реєстрація партнера на маркетплейсі</h1>
				&nbsp;
				<NavLink className='partner-registration__title-link' to='/'>
					www.vyroby.com
				</NavLink>
			</h3>
			<div className='partner-registration--wrap container'>
				{step === 1 && (
					<>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationName'
						>
							<p>Вкажіть ім`я контактної особи</p>
							<input
								className={`partner-registration__form-input ${
									isErrorName ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setName(e.target.value)}
								value={name}
								autoComplete='name'
								id='partner-registrationName'
								name='name'
								type='text'
								placeholder=''
							/>
							{isErrorName && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>

						<div className='partner-registration__phone-wrap'>
							<label
								className='partner-registration__form-input-label partner-registration__form-input-label--phone-main'
								htmlFor='partnerRegistrationPhone'
							>
								<p>Вкажіть телефон контактної особи</p>
								<InputPhone
									phone={phone}
									setPhone={setPhone}
									isPartnerRegistration={true}
									isErrorPhone={isErrorPhone}
								/>
								{isErrorPhone && (
									<div className='order-view__form-error-text--phone'>
										Обов'язкове поле
									</div>
								)}
							</label>
						</div>
					</>
				)}
				{step === 2 && (
					<>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyName'
						>
							<p>Назва компанії</p>
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть назву вашої компанії
							</p>
							<input
								className={`partner-registration__form-input ${
									isErrorCompanyName ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => handleSetCompanyName(e.target.value)}
								value={companyName}
								id='partner-registrationCompanyName'
								type='text'
								placeholder=''
							/>
							{isErrorCompanyName && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationAlias'
						>
							<p>Назва url</p>
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть закінчення url сторінки
							</p>
							<input
								className={`partner-registration__form-input ${
									isErrorAlias ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setAlias(e.target.value)}
								value={alias}
								id='partner-registrationAlias'
								type='text'
								placeholder=''
							/>
							{isErrorAlias && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationSlogan'
						>
							<p>Дескриптор бренду</p>
							<p className='partner-registration__form-input-sub-title'>
								Коротко про бренд 2-7 слів
							</p>
							<input
								className={`partner-registration__form-input ${
									isErrorSlogan ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setSlogan(e.target.value)}
								value={slogan}
								id='partner-registrationSlogan'
								type='text'
								placeholder=''
							/>
							{isErrorSlogan && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyLink'
						>
							<p>Опис про компанію</p>
							<textarea
								className={`partner-registration__form-textarea ${
									isErrorCompanyInfo ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setCompanyInfo(e.target.value)}
								value={companyInfo}
								id='partner-registrationCompanyLink'
								rows={4}
								type='text'
								placeholder=''
							/>
							{isErrorCompanyInfo && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyProducts'
						>
							<p>Товари та послуги</p>
							<p className='partner-registration__form-input-sub-title'>
								Напишіть, які товари та послуги ви пропонуєте
							</p>
							<textarea
								className={`partner-registration__form-textarea ${
									isErrorCompanyProducts ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setCompanyProducts(e.target.value)}
								value={companyProducts}
								id='partner-registrationCompanyProducts'
								rows={1}
								type='text'
								placeholder=''
							/>
							{isErrorCompanyProducts && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationConditionsPayment'
						>
							<p>Умови оплати</p>
							<p className='partner-registration__form-input-sub-title'>
								Опишіть ваші умови по оплаті, наприклад: 50% передоплата, решту
								по отриманні товару, повна оплата після отримання товару або
								інші ваші умови
							</p>
							<textarea
								className={`partner-registration__form-textarea`}
								onChange={(e) => setConditionsPayment(e.target.value)}
								value={conditionsPayment}
								id='partner-registrationConditionsPayment'
								rows={2}
								type='text'
								placeholder=''
							/>
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyPayment'
						>
							<p>Варіанти/способи оплати</p>
							<p className='partner-registration__form-input-sub-title'>
								Введіть по черзі варіанти оплати та натисніть кнопку додати,
								наприклад: На розрахунковий рахунок, Оплата під час отримання
								товару
							</p>
							<div className='partner-registration__form-input-payment-wrap'>
								<input
									className={`partner-registration__form-input-payment ${
										isErrorCompanyPayment ? 'contacts-view__form-error' : ''
									}`}
									onChange={(e) => setCompanyNewPayment(e.target.value)}
									value={companyNewPayment}
									id='partner-registrationCompanyPayment'
									type='text'
									placeholder=''
								/>
								<button
									className='main-btn-1 partner-registration__add-new-payment'
									onClick={handleAddCompanyNewPayment}
								>
									Додати
								</button>
							</div>
							{isErrorCompanyPayment && !companyPayment?.length && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
							{!!companyPayment?.length && (
								<div className='partner-registration__result-payment-wrap'>
									{companyPayment.map((el, i) => (
										<div
											className='partner-registration__result-payment'
											key={el}
										>
											{i + 1}. {el}{' '}
											<img
												onClick={() => handleDelPayment(el)}
												className='partner-registration__result-payment-del-btn'
												src={deleteImg3}
												alt='img'
											/>
										</div>
									))}
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationСonditionsDelivery'
						>
							<p>Умови доставки</p>
							<p className='partner-registration__form-input-sub-title'>
								Опишіть умови вартості доставки, наприклад - доставка
								безкоштовна по всій Україні, доставка оплачується за тарифами
								перевізника або інші ваші умови
							</p>
							<textarea
								className={`partner-registration__form-textarea`}
								onChange={(e) => setConditionsDelivery(e.target.value)}
								value={conditionsDelivery}
								id='partner-registrationConditionsDelivery'
								rows={2}
								type='text'
								placeholder=''
							/>
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyDelivery'
						>
							<p>Варіанти/способи доставки</p>
							<p className='partner-registration__form-input-sub-title'>
								Введіть по черзі варіанти доставки та натисніть кнопку додати,
								наприклад - доставка у відділення Новою Поштою, доставка адресна
								курʼєром Нова Пошта, доставка адресна курʼєром Виробника,
								самовивіз зі складу Виробника або інші ваші варіанти
							</p>
							<div className='partner-registration__form-input-payment-wrap'>
								<input
									className={`partner-registration__form-input-payment ${
										isErrorConditionsDelivery ? 'contacts-view__form-error' : ''
									}`}
									onChange={(e) => setCompanyNewDelivery(e.target.value)}
									value={companyNewDelivery}
									id='partner-registrationCompanyDelivery'
									type='text'
									placeholder=''
								/>
								<button
									className='main-btn-1 partner-registration__add-new-payment'
									onClick={handleAddCompanyNewDelivery}
								>
									Додати
								</button>
							</div>
							{isErrorConditionsDelivery && !companyDelivery?.length && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
							{!!companyDelivery?.length && (
								<div className='partner-registration__result-payment-wrap'>
									{companyDelivery.map((el, i) => (
										<div
											className='partner-registration__result-payment'
											key={el}
										>
											{i + 1}. {el}{' '}
											<img
												onClick={() => handleDelDelivery(el)}
												className='partner-registration__result-payment-del-btn'
												src={deleteImg3}
												alt='img'
											/>
										</div>
									))}
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyLink'
						>
							<p>Сайт</p>
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть посилання на ваш сайт
							</p>
							<input
								className='partner-registration__form-input'
								onChange={(e) => setCompanyLink(e.target.value)}
								value={companyLink}
								id='partner-registrationCompanyLink'
								type='text'
								placeholder=''
							/>
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanySocial'
						>
							<p>Ваші соціальні мережі</p>
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть посилання на Ваші соціальні мережі
							</p>
							<input
								className='partner-registration__form-input'
								onChange={(e) => setCompanySocial(e.target.value)}
								value={companySocial}
								id='partner-registrationCompanySocial'
								type='text'
								placeholder=''
							/>
						</label>
						<label
							className='partner-registration__form-input-label partner-registration__form-input-label--phone'
							htmlFor='partnerRegistrationPhone'
						>
							<p>Вкажіть контакти керівника (власника) компанії</p>
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть контактні дані власника - ПІБ, телефон
							</p>
							<input
								className={`partner-registration__form-input ${
									isErrorCompanyContact ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setCompanyContact(e.target.value)}
								value={companyContact}
								id='partner-registrationCompanyContact'
								type='text'
								placeholder=''
							/>
							{isErrorCompanyContact && (
								<div className='order-view__form-error-text--phone'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyCity'
						>
							<p>Вкажіть місто</p>
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть місто, в якому знаходиться ваша компанія
							</p>
							<input
								className={`partner-registration__form-input ${
									isErrorCompanyCity ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setCompanyCity(e.target.value)}
								value={companyCity}
								id='partner-registrationCompanyCity'
								type='text'
								placeholder=''
							/>
							{isErrorCompanyCity && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyAdressa'
						>
							Вкажіть адресу
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть адресу вашої компанії
							</p>
							<input
								className={`partner-registration__form-input ${
									isErrorCompanyAdressa ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setCompanyAdressa(e.target.value)}
								value={companyAdressa}
								id='partner-registrationCompanyAdressa'
								type='text'
								placeholder=''
							/>
							{isErrorCompanyAdressa && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label partner-registration__form-input-label--phone'
							htmlFor='partnerRegistrationPhone'
						>
							<p>Вкажіть телефон</p>
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть робочий телефон, за яким можна звязатися з Вами
							</p>
							<InputPhone
								phone={companyPhone}
								setPhone={setCompanyPhone}
								isPartnerRegistration={true}
								isErrorPhone={isErrorCompanyPhone}
							/>
							{isErrorCompanyPhone && (
								<div className='order-view__form-error-text--phone'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyEmail'
						>
							<p>Вкажіть email компанії</p>
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть email, на який будуть приходити заявки та замовлення
								клієнтів
							</p>
							<input
								className={`partner-registration__form-input ${
									isErrorCompanyEmail ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setCompanyEmail(e.target.value)}
								value={companyEmail}
								id='partner-registrationCompanyEmail'
								type='text'
								placeholder=''
							/>
							{isErrorCompanyEmail && (
								<div className='contacts-view__form-error-text'>
									{!companyEmail.length
										? "Обов'язкове поле"
										: 'Вкажіть, будь ласка, коректний email'}
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyWork'
						>
							<p>Графік роботи</p>
							<p className='partner-registration__form-input-sub-title'>
								Опишіть графік роботи, за яким працює ваша компанія
							</p>
							<textarea
								className={`partner-registration__form-textarea ${
									isErrorCompanyWork ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setCompanyWork(e.target.value)}
								value={companyWork}
								id='partner-registrationCompanyWork'
								rows={2}
								type='text'
								placeholder=''
							/>
							{isErrorCompanyWork && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<label
							className='partner-registration__form-input-label'
							htmlFor='partner-registrationCompanyLegalName'
						>
							<p>
								Назва юридичної особи, з якої будуть здійснюватися розрахунки
							</p>
							<p className='partner-registration__form-input-sub-title'>
								Вкажіть повну назву ФОП, ТОВ, інше
							</p>
							<input
								className={`partner-registration__form-input ${
									isErrorCompanyLegalName ? 'contacts-view__form-error' : ''
								}`}
								onChange={(e) => setCompanyLegalName(e.target.value)}
								value={companyLegalName}
								id='partner-registrationCompanyLegalName'
								type='text'
								placeholder=''
							/>
							{isErrorCompanyLegalName && (
								<div className='contacts-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</label>
						<div className='partner-registration__input-upload-logo-wrap'>
							<span className='partner-registration__input-upload-title'>
								Завантажте логотип вашої компанії
							</span>
							<p className='partner-registration__input-upload-sub-title'>
								Логотип вашої компанії можливо завантажити в одному з цих
								форматів: .jpg, .jpeg, .png, .svg
							</p>
							<label className='partner-registration__input-upload-wrap'>
								<div className='main-btn-1 partner-registration__upload-img'>
									Завантажити файли
								</div>
								<input
									className='partner-registration__input-upload'
									onChange={(e) => setImageLogoFiles(e.target.files[0])}
									type='file'
									accept='image/*'
									id='orderViewimageLogoFilesg'
								/>
								{isErrorImageLogoFiles && (
									<div className='contacts-view__form-error-text'>
										Обов'язкове поле
									</div>
								)}
							</label>

							{!!imageLogoFiles?.name?.length && (
								<div className='partner-registration__upload-result-logo-wrap'>
									<button
										className='partner-registration__upload-result-del-btn-wrap'
										onClick={() => setImageLogoFiles({})}
									>
										<img
											className='partner-registration__upload-result-del-btn'
											src={deleteImg3}
											alt='img'
										/>
									</button>
									<img
										className='partner-registration__upload-img-logo-result'
										src={URL.createObjectURL(imageLogoFiles)}
										alt='img'
									/>
								</div>
							)}
						</div>
						<div
							className={`partner-registration__input-upload-banner-wrap ${
								!!imageBannerFiles?.name?.length
									? 'partner-registration__input-upload-banner-wrap--is-img'
									: ''
							}`}
						>
							<span className='partner-registration__input-upload-title'>
								Завантажте обкладинку для вашої сторінки - по бажанню
							</span>
							<p className='partner-registration__input-upload-sub-title'>
								Розмір зображення 1920х300 px. Зображення в таких форматах:
								.jpg, .jpeg, .png
							</p>
							<label className='partner-registration__input-upload-wrap'>
								<div className='main-btn-1 partner-registration__upload-img'>
									Завантажити файли
								</div>
								<input
									className='partner-registration__input-upload'
									onChange={(e) => setImageBannerFiles(e.target.files[0])}
									type='file'
									accept='image/*'
									id='orderViewimageBannerFiles'
								/>
							</label>

							{!!imageBannerFiles?.name?.length && (
								<div className='partner-registration__upload-result-banner-wrap'>
									<button
										className='partner-registration__upload-result-del-btn-banner-files-wrap'
										onClick={() => setImageBannerFiles({})}
									>
										<img
											className='partner-registration__upload-result-del-btn'
											src={deleteImg3}
											alt='img'
										/>
									</button>
									<img
										className='partner-registration__upload-img-banner-result'
										src={URL.createObjectURL(imageBannerFiles)}
										alt='img'
									/>
								</div>
							)}
						</div>
						<div className='partner-registration__input-upload-banner-wrap'>
							<span className='partner-registration__input-upload-title'>
								Завантажте юридичні документи реєстрації компанії
							</span>
							<p className='partner-registration__input-upload-sub-title'>
								Додайте фото, скан-копію юридичних документів вашої компанії.
							</p>
							<label className='partner-registration__input-upload-wrap'>
								<div className='main-btn-1 partner-registration__upload-img'>
									Завантажити файли
								</div>
								<input
									className='partner-registration__input-upload'
									onChange={(e) => handleUploudDocFiles(e.target.files[0])}
									type='file'
									accept='pdf, doc, docx, jpeg, png, jpg, csv'
								/>
								{isErrorDocFiles && (
									<div className='contacts-view__form-error-text'>
										Обов'язкове поле
									</div>
								)}
							</label>

							{!!imageDocFilesArr?.length &&
								imageDocFilesArr.map((el) => (
									<div
										className='partner-registration__upload-result-wrap'
										key={el.name}
									>
										<button onClick={() => handleDeleteDocFiles(el.name)}>
											<img
												className='partner-registration__upload-result-del-btn'
												src={deleteImg3}
												alt='img'
											/>
										</button>
										{window.innerWidth > 640 ? (
											<div className='partner-registration__upload-img-doc-result'>
												{el.name?.length > 60 ? el.name.slice(-60) : el.name}
											</div>
										) : (
											<div className='partner-registration__upload-img-doc-result'>
												{el.name?.length > 35 ? el.name.slice(-35) : el.name}
											</div>
										)}
									</div>
								))}
						</div>
					</>
				)}

				<div className='partner-registration__btn--wrap'>
					{(isErrorName ||
						isErrorAlias ||
						isErrorDocFiles ||
						isErrorConditionsDelivery ||
						isErrorCompanyPayment ||
						isErrorPhone ||
						isErrorCompanyEmail ||
						isErrorName ||
						isErrorImageLogoFiles ||
						isErrorCompanyProducts ||
						isErrorCompanyInfo ||
						isErrorCompanyLegalName ||
						isErrorCompanyWork ||
						isErrorSlogan ||
						isErrorCompanyContact ||
						isErrorPhone ||
						isErrorCompanyName ||
						isErrorCompanyCity ||
						isErrorCompanyAdressa ||
						isErrorCompanyPhone) && (
						<div className='contacts-view__form-error-banner'>
							{(isErrorName ||
								isErrorAlias ||
								isErrorDocFiles ||
								isErrorConditionsDelivery ||
								isErrorCompanyPayment ||
								isErrorImageLogoFiles ||
								isErrorCompanyProducts ||
								isErrorCompanyInfo ||
								isErrorCompanyLegalName ||
								isErrorCompanyWork ||
								isErrorSlogan ||
								isErrorCompanyContact ||
								isErrorPhone ||
								isErrorCompanyName ||
								isErrorCompanyCity ||
								isErrorCompanyAdressa ||
								isErrorCompanyPhone) && (
								<div className='contacts-view__form-error-banner-text'>
									Будь ласка, заповніть всі обов'язкові поля
								</div>
							)}
							{((isErrorCompanyEmail &&
								!isErrorName &&
								!isErrorAlias &&
								!isErrorDocFiles &&
								!isErrorConditionsDelivery &&
								!isErrorCompanyPayment &&
								!isErrorImageLogoFiles &&
								!isErrorCompanyProducts &&
								!isErrorCompanyInfo &&
								!isErrorCompanyLegalName &&
								!isErrorCompanyWork &&
								!isErrorSlogan &&
								!isErrorCompanyContact &&
								!isErrorPhone &&
								!isErrorCompanyName &&
								!isErrorCompanyCity &&
								!isErrorCompanyAdressa &&
								!isErrorCompanyPhone) ||
								!!companyEmail.length) && (
								<div className='contacts-view__form-error-banner-text'>
									{!companyEmail.length
										? "Будь ласка, заповніть всі обов'язкові поля"
										: 'Вкажіть, будь ласка, коректний email'}
								</div>
							)}
						</div>
					)}

					<div className='partner-registration__btn-wrap'>
						{step === 2 && (
							<button
								className='main-btn-2 partner-registration__btn-back'
								onClick={() => setStep(1)}
							>
								<svg
									className='partner-registration__btn-back-img'
									width='20px'
									height='20px'
									viewBox='0 0 24 24'
									xmlns='http://www.w3.org/2000/svg'
								>
									<title />
									<g id='Complete'>
										<g id='arrow-left'>
											<g>
												<polyline
													className='partner-registration__btn-back-img-stroke'
													data-name='Right'
													fill='none'
													id='Right-2'
													points='7.6 7 2.5 12 7.6 17'
													stroke='#19191D'
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
												/>
												<line
													className='partner-registration__btn-back-img-stroke'
													fill='none'
													stroke='#19191D'
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													x1='21.5'
													x2='4.8'
													y1='12'
													y2='12'
												/>
											</g>
										</g>
									</g>
								</svg>
								<span>Назад</span>
							</button>
						)}
						{step === 1 && (
							<div className='partner-registration__step-1-wrap'>
								<div
									className='partner-registration__btn-registration-wrap'
									onClick={handleNextStep}
								>
									<button className='main-btn-1 partner-registration__btn'>
										<span>Інформація про компанію</span>
										<svg
											className='partner-registration__btn-img'
											width='20px'
											height='20px'
											viewBox='0 0 24 24'
											xmlns='http://www.w3.org/2000/svg'
										>
											<title />
											<g id='Complete'>
												<g id='arrow-left'>
													<g>
														<polyline
															data-name='Right'
															fill='none'
															id='Right-2'
															points='7.6 7 2.5 12 7.6 17'
															stroke='#fff'
															strokeLinecap='round'
															strokeLinejoin='round'
															strokeWidth='2'
														/>
														<line
															fill='none'
															stroke='#fff'
															strokeLinecap='round'
															strokeLinejoin='round'
															strokeWidth='2'
															x1='21.5'
															x2='4.8'
															y1='12'
															y2='12'
														/>
													</g>
												</g>
											</g>
										</svg>
									</button>
								</div>
								<div className={`partner-registration__step-wrap`}>
									{step}/2
								</div>
							</div>
						)}
						{step === 2 && (
							<>
								<button
									className='main-btn-1 partner-registration__btn-send'
									onClick={hendleSend}
								>
									<span>Відправити</span>
								</button>
								<div className={`partner-registration__step-wrap`}>
									{step}/2
								</div>
							</>
						)}
					</div>
				</div>

				<div className='partner-registration__text-warning'>
					Натискаючи на кнопку, ви даєте згоду на обробку персональних даних та
					погоджуєтеся з умовами &nbsp;
					<a
						className='partner-registration__text-warning-linc'
						href='/offer'
						target='_blank'
					>
						договору публічної оферти
					</a>
				</div>
			</div>
		</div>
	);
}

export default PartnerRegistrationView;
