import './CheckoutView.css';
import { useState, useEffect, useMemo } from 'react';
import close from '../../assets/close.svg';
import noPhotos from '../../assets/noPhotos.svg';
import logo from '../../assets/logo.svg';
import arrowLeft from '../../assets/arrowLeft.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteShoppingProducts,
	setIsSuccessCheckoutView,
	setShoppingProducts,
} from '../../store/homeSlice';
import { NavLink, useNavigate } from 'react-router-dom';
import {
	fetchRequest,
	handleCheckShortId,
	handlePriceReplace,
	handleReturnItemVariantGtm,
	handleReturnProductCategoryAll,
	validEmail,
} from '../../helpers/Utils';
import ShoppingDelivery from '../../components/ShoppingDelivery/ShoppingDelivery';
import ShoppingComment from '../../components/ShoppingComment/ShoppingComment';
import InputPhone from '../../components/InputPhone/InputPhone';
import ColorResult from '../../components/ColorResult/ColorResult';
import MaterialResult from '../../components/MaterialResult/MaterialResult';

function CheckoutView() {
	const shoppingProducts = useSelector(
		(state) => state.homeSlice.shoppingProducts
	);
	const utm_source = useSelector((state) => state.homeSlice.utm_source);
	const utm_medium = useSelector((state) => state.homeSlice.utm_medium);
	const utm_campaign = useSelector((state) => state.homeSlice.utm_campaign);
	const categoriesShop = useSelector((state) => state.homeSlice.categoriesShop);
	const [totalPrice, setTotalPrice] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [address, setAddress] = useState('');
	const [comment, setComment] = useState([]);
	const [vendorArr, setVendorArr] = useState([]);
	const [isErrorFirstName, setIsErrorFirstName] = useState(false);
	const [isErrorLastName, setIsErrorLastName] = useState(false);
	const [isErrorPhone, setIsErrorPhone] = useState(false);
	const [isErrorEmail, setIsErrorEmail] = useState(false);
	const [isErrorAddress, setIsErrorAddress] = useState(false);
	const [isBeginCheckout, setIsBeginCheckout] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const allVedorsEqual = shoppingProducts.every(
		(val) => val.vendor_id === shoppingProducts[0].vendor_id
	);

	useEffect(() => {
		let localshoppingProducts = localStorage.getItem('localShoppingProducts');
		if (localshoppingProducts?.length && !shoppingProducts?.length) {
			let data = {
				ids: [...JSON.parse(localshoppingProducts).map((el) => el._id)],
			};
			fetchRequest(
				'POST',
				`${process.env.REACT_APP_BASE_URL}/products/array`,
				data
			).then((res) => {
				if (res?.success && res.data?.length) {
					let arr = res.data.map((el) => {
						return {
							...el,
							count: JSON.parse(localshoppingProducts).find(
								(ell) => ell._id === el._id
							).count,
						};
					});
					dispatch(setShoppingProducts([...arr]));
				}
			});
		}
	}, []);

	useEffect(() => {
		if (firstName?.length) {
			setIsErrorFirstName(false);
			handleaBeginCheckoutGtm(shoppingProducts);
			setIsBeginCheckout(true);
		}
	}, [firstName]);

	useEffect(() => {
		if (lastName?.length) {
			setIsErrorLastName(false);
			handleaBeginCheckoutGtm(shoppingProducts);
			setIsBeginCheckout(true);
		}
	}, [lastName]);

	useEffect(() => {
		if (phone?.length && phone.length >= 12) {
			setIsErrorPhone(false);
			handleaBeginCheckoutGtm(shoppingProducts);
			setIsBeginCheckout(true);
		}
	}, [phone]);

	useEffect(() => {
		if (email?.length) {
			setIsErrorEmail(false);
			handleaBeginCheckoutGtm(shoppingProducts);
			setIsBeginCheckout(true);
		}
	}, [email]);

	useEffect(() => {
		if (address?.length) {
			setIsErrorAddress(false);
			handleaBeginCheckoutGtm(shoppingProducts);
			setIsBeginCheckout(true);
		}
	}, [address]);

	useEffect(() => {
		if (shoppingProducts?.length) {
			let res = shoppingProducts.reduce(
				(total, obj) => total + obj.count * obj.price,
				0
			);
			setTotalPrice(res + '');
			setVendorArr([...new Set(shoppingProducts.map((el) => el.vendor_id))]);
		} else {
			setVendorArr([]);
			setTotalPrice('0');
		}
	}, [shoppingProducts]);

	const handleEditeProductCount = (str, id) => {
		let res = shoppingProducts.map((el) => {
			if (el._id === id) {
				if (str === '-1') {
					el = { ...el, count: el.count - 1 > 0 ? el.count - 1 : 1 };
				} else {
					el = { ...el, count: el.count + 1 };
				}
			}
			return el;
		});

		dispatch(setShoppingProducts(res));
	};

	const handleaPurchaseGtm = (arr) => {
		if (arr?.length) {
			arr.map((item) => {
				window.dataLayer.push({ ecommerce: null });
				window.dataLayer.push({
					event: 'purchase',
					ecommerce: {
						transaction_id: item.id,
						affiliation: 'cart',
						value: shoppingProducts
							.filter((ell) => ell.vendor_id === item.vendor_id)
							.reduce((total, obj) => total + obj.count * obj.price, 0)
							.toFixed(2),
						tax: '0',
						shipping: '0',
						currency: 'UAH',
						items: [
							...shoppingProducts
								.filter((ell) => ell.vendor_id === item.vendor_id)
								.map((el) => {
									return {
										item_name: el.title,
										item_id: el._id,
										id: el._id,
										price: el.price.toFixed(2),
										item_brand: el.vendor_name,
										item_category: handleReturnProductCategoryAll(
											el,
											categoriesShop
										)?.cat?.name?.length
											? handleReturnProductCategoryAll(el, categoriesShop)?.cat
													?.name
											: '',
										item_category2: handleReturnProductCategoryAll(
											el,
											categoriesShop
										)?.subCat?.name
											? handleReturnProductCategoryAll(el, categoriesShop)
													?.subCat?.name
											: '',
										item_variant: handleReturnItemVariantGtm(el),
										quantity: Number(el.count),
										google_business_vertical: 'retail',
									};
								}),
						],
					},
				});
			});
		}
	};

	const handleaBeginCheckoutGtm = (products) => {
		if (!isBeginCheckout) {
			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push({
				event: 'begin_checkout',
				ecommerce: {
					items: [
						...products.map((el) => {
							return {
								item_name: el.title,
								item_id: el._id,
								price: el.price.toFixed(2),
								item_brand: el.vendor_name,
								item_category: handleReturnProductCategoryAll(
									el,
									categoriesShop
								)?.cat?.name?.length
									? handleReturnProductCategoryAll(el, categoriesShop)?.cat
											?.name
									: '',
								item_category2: handleReturnProductCategoryAll(
									el,
									categoriesShop
								)?.subCat?.name
									? handleReturnProductCategoryAll(el, categoriesShop)?.subCat
											?.name
									: '',
								item_variant: handleReturnItemVariantGtm(el),
								quantity: Number(el.count),
							};
						}),
					],
				},
			});
		}
	};

	const handleaRemoveFromCartGtm = (product) => {
		let res = handleReturnProductCategoryAll(product, categoriesShop);

		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'remove_from_cart',
			ecommerce: {
				items: [
					{
						item_name: product.title,
						item_id: product._id,
						price: product.price.toFixed(2),
						item_brand: product.vendor_name,
						item_category: res?.cat?.name?.length ? res?.cat?.name : '',
						item_category2: res?.subCat?.name ? res?.subCat?.name : '',
						item_variant: handleReturnItemVariantGtm(product),
						quantity: Number(product.count),
					},
				],
			},
		});
	};

	const handleDeleteProduct = (obj) => {
		dispatch(deleteShoppingProducts(obj._id));
		handleaRemoveFromCartGtm(obj);
	};

	const handleCheckVariations = useMemo(() => {
		const check = (product) => {
			let res = [];
			if (!!product.characteristics?.length) {
				let resArr = product.characteristics.filter((ell) =>
					product.variations.some(
						(characteristic) => characteristic.name === ell.name
					)
				);
				res = resArr?.length ? [...resArr] : [];
			}
			return res;
		};
		return check;
	}, [shoppingProducts]);

	const handleBack = () => {
		window.history.back();
	};

	const handleTotalProductPrice = (obj) => {
		return obj.count * obj.price;
	};

	const handleSendPurchase = (strId) => {
		let sendBtn = document.getElementById(strId);
		sendBtn.style.cssText = `
            pointer-events: none;
        `;
		if (
			shoppingProducts?.length &&
			address?.length &&
			lastName?.length &&
			firstName?.length &&
			phone?.length &&
			phone.length >= 12 &&
			validEmail(email)
		) {
			let data = {
				full_name: lastName + ' ' + firstName,
				email: email,
				phone: phone,
				delivery_address: address,
				comment: comment,
				products: shoppingProducts,
				utm_source: utm_source?.length ? utm_source : null,
				utm_medium: utm_medium?.length ? utm_medium : null,
				utm_campaign: utm_campaign?.length ? utm_campaign : null,
			};

			fetchRequest(
				'POST',
				`${process.env.REACT_APP_BASE_URL}/orders/`,
				data
			).then((res) => {
				if (res.success) {
					handleaPurchaseGtm(res.data);
					dispatch(setIsSuccessCheckoutView(true));

					dispatch(setShoppingProducts([]));
					setFirstName('');
					setLastName('');
					setEmail('');
					setPhone('');
					setAddress('');
					setComment('');
					navigate('/success');
				} else {
					console.log('POST CheckoutView:', res);
				}
			});
		} else {
			sendBtn.style.cssText = ``;
			setIsErrorFirstName(!firstName?.length ? true : false);
			setIsErrorLastName(!lastName?.length ? true : false);
			setIsErrorPhone(!phone?.length || phone.length < 12 ? true : false);
			setIsErrorEmail(!email.length || !validEmail(email) ? true : false);
			setIsErrorAddress(!address?.length ? true : false);
		}
	};

	return (
		<div className='checkout-view__header--wrap'>
			<div className='checkout-view__header container'>
				<NavLink to='/'>
					<img className='checkout-view__header-logo' src={logo} alt='img' />
				</NavLink>
				<button className='checkout-view__back-btn' onClick={handleBack}>
					<img
						className='checkout-view__back-btn-img'
						src={arrowLeft}
						alt='img'
					/>
					<p className='checkout-view__back-btn-text-wrap'>
						<span className='checkout-view__back-btn-text'>повернутися</span>{' '}
						<span>назад</span>
					</p>
				</button>
			</div>
			<div className='checkout-view__card-form-wrap container'>
				<div className='checkout-view__card-form'>
					<div className='checkout-view__card-form-title'>
						Оформлення замовлення
					</div>
					{!allVedorsEqual ? (
						<h1 className='checkout-view__card-form-text'>
							Зверніть увагу - товари різних виробників в замовленні будуть
							опрацьовані окремо.
						</h1>
					) : null}
					<div className='checkout-view__form-wrap'>
						<div className='checkout-view__form-input--wrap'>
							<div className='checkout-view__form-input-wrap'>
								<label
									className='checkout-view__form-input-label'
									htmlFor='checkout-viewName'
								>
									<span>Ім`я</span>
								</label>
								<input
									className={`checkout-view__form-input ${
										isErrorFirstName ? 'order-view__form-error' : ''
									}`}
									onChange={(e) => setFirstName(e.target.value)}
									value={firstName}
									autoComplete='name'
									id='checkout-viewName'
									name='name'
									type='text'
									placeholder=''
								/>
								{isErrorFirstName && (
									<div className='order-view__form-error-text'>
										Обов'язкове поле
									</div>
								)}
							</div>
							<div className='checkout-view__form-input-wrap'>
								<label
									className='checkout-view__form-input-label'
									htmlFor='checkout-viewName'
								>
									<span>Прізвище</span>
								</label>
								<input
									className={`checkout-view__form-input ${
										isErrorLastName ? 'order-view__form-error' : ''
									}`}
									onChange={(e) => setLastName(e.target.value)}
									value={lastName}
									autoComplete='name'
									id='checkout-viewName'
									name='name'
									type='text'
									placeholder=''
								/>
								{isErrorLastName && (
									<div className='order-view__form-error-text'>
										Обов'язкове поле
									</div>
								)}
							</div>

							<div
								className={`checkout-view__form-error-wrap ${
									isErrorPhone ? 'order-view__form-error--phone' : ''
								}`}
							>
								<label
									className='checkout-view__form-input-label'
									htmlFor='checkout-viewPhone'
								>
									Телефон
								</label>
								<InputPhone
									phone={phone}
									setPhone={setPhone}
									isCheckoutView={true}
									isErrorPhone={isErrorPhone}
								/>
								{isErrorPhone && (
									<div className='checkout-view__form-error-text--phone'>
										Обов'язкове поле
									</div>
								)}
							</div>

							<div className='checkout-view__form-input-wrap'>
								<label
									className='checkout-view__form-input-label'
									htmlFor='checkout-viewEmail'
								>
									Email
								</label>
								<input
									className={`checkout-view__form-input ${
										isErrorEmail ? 'order-view__form-error' : ''
									}`}
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									autoComplete='email'
									id='checkout-viewEmail'
									name='email'
									type='text'
									placeholder=''
								/>
								{isErrorEmail && (
									<div className='order-view__form-error-text'>
										{!email.length
											? "Обов'язкове поле"
											: 'Вкажіть, будь ласка, коректний email'}
									</div>
								)}
							</div>
						</div>

						<div className='checkout-view__form-input-wrap-addres'>
							<label
								className='checkout-view__form-input-label'
								htmlFor='checkout-viewaddress'
							>
								Адреса доставки
							</label>
							<input
								className={`checkout-view__form-input ${
									isErrorAddress ? 'order-view__form-error' : ''
								}`}
								onChange={(e) => setAddress(e.target.value)}
								value={address}
								autoComplete='address'
								id='checkout-viewAddress'
								name='address'
								type='text'
								placeholder={`${
									window.innerWidth >= 640
										? 'Місто, вулиця, будинок / Місто, № відділення пошти'
										: 'Місто, вулиця, будинок / № відділення пошти'
								}`}
							/>
							{isErrorAddress && (
								<div className='order-view__form-error-text'>
									Обов'язкове поле
								</div>
							)}
						</div>
					</div>

					<div className='checkout-view__card-products'>
						{!!vendorArr?.length ? (
							vendorArr.map((el, index) => {
								let res = [];
								if (shoppingProducts?.length) {
									let arr = shoppingProducts.filter(
										(ell) => ell.vendor_id === el
									);
									if (arr?.length) {
										res = [...arr];
									}
								}
								return (
									<div
										className='checkout-view__product--wrap'
										key={`vendor-${index}`}
									>
										{res.map((product, i) => (
											<div
												className='checkout-view__card-product-wrap'
												key={product._id}
											>
												{i === 0 && (
													<div>
														<div className='checkout-view__card-form-sub-title'>
															Замовлення № {index + 1}
														</div>
														<div className='checkout-view__card-product-vendor-wrap'>
															<div className='checkout-view__card-product-vendor'>
																Бренд: {product.vendor_name}
															</div>
															<div className='checkout-view__card-product-vendor-total'>
																Сума:{' '}
																<span>
																	{handlePriceReplace(
																		res.reduce(
																			(total, item) =>
																				total + item.count * item.price,
																			0
																		)
																	)}{' '}
																	грн
																</span>
															</div>
														</div>
													</div>
												)}
												<div className='checkout-view__card-product--wrap'>
													<div className='checkout-view__card-product'>
														<NavLink to={handleCheckShortId(product)}>
															<img
																className='checkout-view__card-product-img'
																src={
																	!!product.images?.length
																		? product.images[0]
																		: noPhotos
																}
																alt='img'
															/>
														</NavLink>
														<div className='checkout-view__card-product-info'>
															<div className='checkout-view__card-product-info-name-wrap'>
																<NavLink
																	className='checkout-view__card-product-info-name'
																	to={handleCheckShortId(product)}
																>
																	{product.title}
																</NavLink>
																<div className='checkout-view__card-product-info-name-code'>
																	{product.code}
																</div>
																{!!product.variations?.length &&
																	product.variations.some(
																		(ell) => ell.name === 'Колір'
																	) && (
																		<div className='shopping-cart__modal-product-info-variations'>
																			<span>{product.color_name}:</span>
																			<ColorResult
																				productColor={product.color}
																				color={product.color}
																			/>
																		</div>
																	)}
																{!!product.variations?.length &&
																	product.variations.some(
																		(ell) => ell.name === 'Матеріал'
																	) && (
																		<div className='shopping-cart__modal-product-info-variations'>
																			<span>{product.material.title}:</span>
																			<MaterialResult
																				productMaterial={product.material}
																				variation={product.material}
																			/>
																		</div>
																	)}
																{!!product.variations?.length &&
																	!!handleCheckVariations(product)?.length &&
																	handleCheckVariations(product).map(
																		(charact) => (
																			<div
																				className='shopping-cart__modal-product-info-variations'
																				key={charact.name}
																			>
																				<span>{charact.name}:</span>
																				<span>{charact.value}</span>
																			</div>
																		)
																	)}
															</div>
															<div className='checkout-view__card-product-info-price--wrap'>
																<div className='checkout-view__card-product-info-price-wrap'>
																	<button
																		className='checkout-view__card-product-info-price-count-btn'
																		onClick={() =>
																			handleEditeProductCount('-1', product._id)
																		}
																	>
																		-
																	</button>
																	{product.count}
																	<button
																		className='checkout-view__card-product-info-price-count-btn'
																		onClick={() =>
																			handleEditeProductCount('+1', product._id)
																		}
																	>
																		+
																	</button>
																</div>
																<div className='checkout-view__card-product-info-price'>
																	{handlePriceReplace(
																		handleTotalProductPrice(product)
																	)}{' '}
																	грн
																</div>
															</div>
														</div>
														<button
															className='checkout-view__card-product-delete-btn'
															onClick={() => handleDeleteProduct(product)}
														>
															<img src={close} alt='img' />
														</button>
													</div>
												</div>
												{i === res.length - 1 && (
													<div className='checkout-view__card-form-delivery-wrap'>
														<ShoppingDelivery vendorId={product.vendor_id} />
														<ShoppingComment
															vendorId={product.vendor_id}
															setComment={setComment}
															comment={comment}
														/>
													</div>
												)}
											</div>
										))}
									</div>
								);
							})
						) : (
							<div className='checkout-view__product-error'>
								Ваш кошик пустий!!!
							</div>
						)}
					</div>
				</div>
				<div className='checkout-view__buy--wrap checkout-view__buy--wrap-mob'>
					<div className='checkout-view__buy-wrap'>
						<div className='checkout-view__form-price-wrap'>
							<div className='checkout-view__form-price'>
								Загальна сума:{' '}
								{totalPrice?.length ? handlePriceReplace(totalPrice) : 0} грн
							</div>
							{(isErrorFirstName ||
								isErrorLastName ||
								isErrorAddress ||
								isErrorEmail ||
								isErrorPhone) && (
								<div className='contacts-view__form-error-banner checkout-view__form-error-banner'>
									{(isErrorFirstName ||
										isErrorLastName ||
										isErrorAddress ||
										isErrorPhone) && (
										<div className='contacts-view__form-error-banner-text'>
											Будь ласка, заповніть всі обов'язкові поля
										</div>
									)}
									{isErrorEmail &&
										((!isErrorFirstName &&
											!isErrorLastName &&
											!isErrorAddress &&
											!isErrorPhone) ||
											!!email.length) && (
											<div className='contacts-view__form-error-banner-text'>
												{!email.length
													? "Будь ласка, заповніть всі обов'язкові поля"
													: 'Вкажіть, будь ласка, коректний email'}
											</div>
										)}
								</div>
							)}
							<button
								id='checkoutViewSendBtnId1'
								className='checkout-view__form-send-btn-buy'
								onClick={() => handleSendPurchase('checkoutViewSendBtnId1')}
							>
								Оформити замовлення
							</button>
						</div>
						<div className='checkout-view__form-bottom'>
							Натискаючи на кнопку, ви даєте згоду на обробку персональних даних
							та погоджуєтеся з умовами &nbsp;
							<a
								className='checkout-view__form-bottom-link'
								href='/offer'
								target='_blank'
							>
								договору публічної оферти
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className='checkout-view__buy--wrap checkout-view__buy--wrap-des'>
				<div className='checkout-view__buy-wrap'>
					<div className='checkout-view__form-price-wrap'>
						<div className='checkout-view__form-price'>
							Загальна сума: {handlePriceReplace(totalPrice)} грн
						</div>
						{(isErrorFirstName ||
							isErrorLastName ||
							isErrorAddress ||
							isErrorEmail ||
							isErrorPhone) && (
							<div className='contacts-view__form-error-banner checkout-view__form-error-banner'>
								{(isErrorFirstName ||
									isErrorLastName ||
									isErrorAddress ||
									isErrorPhone) && (
									<div className='contacts-view__form-error-banner-text'>
										Будь ласка, заповніть всі обов'язкові поля
									</div>
								)}
								{isErrorEmail &&
									((!isErrorFirstName &&
										!isErrorLastName &&
										!isErrorAddress &&
										!isErrorPhone) ||
										!!email.length) && (
										<div className='contacts-view__form-error-banner-text'>
											{!email.length
												? "Будь ласка, заповніть всі обов'язкові поля"
												: 'Вкажіть, будь ласка, коректний email'}
										</div>
									)}
							</div>
						)}
						<button
							id='checkoutViewSendBtnId2'
							className='checkout-view__form-send-btn-buy'
							onClick={() => handleSendPurchase('checkoutViewSendBtnId2')}
						>
							Оформити замовлення
						</button>
					</div>
					<div className='checkout-view__form-bottom'>
						Натискаючи на кнопку, ви даєте згоду на обробку персональних даних
						та погоджуєтеся з умовами &nbsp;
						<a
							className='checkout-view__form-bottom-link'
							href='/offer'
							target='_blank'
						>
							договору публічної оферти
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CheckoutView;
